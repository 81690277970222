import { CounterNotificationTimeOptions } from '../../constants/notification';
import { IBehavior, IDataFilter, IMapper, INotification, INotificationStyle, IUrlFilter } from '../../types';
import { IPlatform } from '../../types/platform';
import { AddFieldMapper, AddStyleAction, AuthorizePlatformAction, ChoosePlatformAction, ChooseSpecificMetricAction, SetBehaviorAction, SetCounterNotificationText, SetCounterNotificationTime, SetDataFilterAction, SetImages, SetNotificationAction, SetNotificationLabel, SetPlatFormData, SetUrlFilterAction, ToggleNextAction } from './notification.action.d';


export const setNotificationFromStorage = (payload: INotification): SetNotificationAction => ({
    type: "SET_NOTIFICATION",
    payload
});

export const setPlatform = (payload: IPlatform): ChoosePlatformAction => ({
    type: "CHOOSE_PLATFORM",
    payload
});

export const authorizePlatform = (payload: { accessToken: string; refreshToken: string; shop: string; authCode: string }): AuthorizePlatformAction => ({
    type: "AUTHORIZE_PLATFORM",
    payload
});

export const chooseMetric = (payload: string): ChooseSpecificMetricAction => ({
    type: "CHOOSE_METRIC",
    payload
});

export const addStyle = (payload: INotificationStyle): AddStyleAction => ({
    type: "ADD_STYLE",
    payload
});

export const setPlatformData = (payload: any): SetPlatFormData => ({
    type: "SET_PLATFORM_DATA",
    payload
});

export const addMapper = (payload: IMapper): AddFieldMapper => ({
    type: "ADD_FIELD_MAPPER",
    payload
})

export const setImages = (payload: string[]): SetImages => ({
    type: "SET_IMAGES",
    payload
})

export const setDataFilters = (payload: IDataFilter[]): SetDataFilterAction => ({
    type: "SET_DATA_FILTERS",
    payload
})

export const setUrlFilter = (payload: IUrlFilter): SetUrlFilterAction => ({
    type: "SET_URL_FILTER",
    payload
})

export const setBehavior = (payload: IBehavior): SetBehaviorAction => ({
    type: "SET_BEHAVIOR_FILTER",
    payload
})

export const setNotificationLabel = (payload: string): SetNotificationLabel => ({
    type: "SET_NOTIFICATION_LABEL",
    payload
})

export const setCounterNotificationTime = (payload: CounterNotificationTimeOptions): SetCounterNotificationTime => ({
    type: "SET_COUNTER_NOTIFICATION_TIME",
    payload
})

export const setNotificationNextStatus = (payload: boolean): ToggleNextAction => ({
    type: "TOGGLE_NOTIFICATION_NEXT",
    payload
})
