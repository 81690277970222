import { useContext } from "react";
import { setNotificationLabel } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";

const LabelNotification = () => {
    const { state, dispatch } = useContext(NotificationContext);


    const setLabel = (label: string) => {
        dispatch(setNotificationLabel(label));
    }

    return (
        <input value={state.label} onChange={(e) => setLabel(e.target.value)} className="p-2 border-2 mr-6 mt-2 rounded w-[100%]" placeholder="Pick a name for your new social proof notification " />
    )
}

export default LabelNotification