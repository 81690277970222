import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axiosInstance, { validateAxios } from "../../../config/axios"
import { NotificationSteps, NotificationTypes } from "../../../constants/notification"
import { mapCurrentNotificationToBackendEdit } from "../../../lib/notification"
import { checkNextDisabilityStatus } from "../../../lib/validateState"
import { IBackendNotification } from "../../../types"
import EditBehaviorFilter from "../BehaviorFilter/edit"
import EditCounterMapper from "../CounterMapper/edit"
import EditDataFilter from "../DataFilter/edit"
import EditLabelNotification from "../LabelNotification/edit"
import EditMapper from "../Mapper/edit"
import NotificationStyle from "../NotificationStyle"
import EditNotificationStyle from "../NotificationStyle/edit"
import EditPublishNotification from "../Publish/edit"
import EditUrlFilter from "../UrlFilter/edit"

interface IEditNotificationWithStepsProps {
    data: IBackendNotification
}


const steps = [NotificationSteps.NOTIFICATION_STYLE, NotificationSteps.MAPPER, NotificationSteps.DATA_FILTER, NotificationSteps.URL_FILTER, NotificationSteps.BEHAVIOR_FILTER, NotificationSteps.LABEL_NOTIFICATION, NotificationSteps.PUBLISH_NOTIFICATION];

const EditNotificationWithSteps = ({ data }: IEditNotificationWithStepsProps) => {
    const [loading, setLoading] = useState(false);
    const [step, setSteps] = useState(NotificationSteps.NOTIFICATION_STYLE);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const [notification, setNotification] = useState<IBackendNotification>(data);

    useEffect(() => {
        if (data) {
            setNotification(data);
        }
    }, [data])


    const componentWithStep = () => {
        switch (step) {
            case NotificationSteps.NOTIFICATION_STYLE:
                return <EditNotificationStyle state={notification} dispatch={setNotification} />
            case NotificationSteps.MAPPER: {
                if (notification.type === NotificationTypes.COUNTER) {
                    return <EditCounterMapper state={notification} dispatch={setNotification} />
                }
                return <EditMapper state={notification} dispatch={setNotification} />
            }
            case NotificationSteps.DATA_FILTER: return <EditDataFilter state={notification} dispatch={setNotification} />
            case NotificationSteps.URL_FILTER: return <EditUrlFilter state={notification} dispatch={setNotification} />
            case NotificationSteps.BEHAVIOR_FILTER: return <EditBehaviorFilter state={notification} dispatch={setNotification} />
            case NotificationSteps.LABEL_NOTIFICATION: return <EditLabelNotification state={notification} dispatch={setNotification} />
            case NotificationSteps.PUBLISH_NOTIFICATION: return <EditPublishNotification state={notification} dispatch={setNotification} />
            default:
                return <NotificationStyle />
        }
    }

    const publishNotification = async () => {
        const notificationForBackend = mapCurrentNotificationToBackendEdit(notification);
        await axiosInstance.put("/notification/" + notification._id, notificationForBackend);
        navigate("/notifications");
    }



    const goForward = async () => {

        if (step === NotificationSteps.PUBLISH_NOTIFICATION) {
            setLoading(true);
            const error = await validateAxios(publishNotification)
            if (error) {
                setError(error);
                setLoading(false);
            }
            return;
        }

        const nextStep = steps.findIndex((eachStep) => eachStep === step);
        const indexOfNextStep = Math.min(nextStep + 1, steps.length - 1);
        setSteps(steps[indexOfNextStep]);
    }

    const goBack = () => {
        const nextStep = steps.findIndex((eachStep) => eachStep === step);
        const indexOfNextStep = Math.max(nextStep - 1, 0);
        setSteps(steps[indexOfNextStep]);
    }



    if (!data) {
        return null;
    }

    return (
        <div>
            {componentWithStep()}
            <div className="flex items-center w-[100%] justify-around my-3">
                <button disabled={step === NotificationSteps.NOTIFICATION_STYLE || loading} onClick={goBack} className="bg-[#2F82FF] text-white p-1 rounded-md w-[200px] border-2  disabled:bg-gray-500 disabled:text-white">
                    Back
                </button>
                <button disabled={checkNextDisabilityStatus(step, notification) || loading} onClick={goForward} className="bg-[#2F82FF] text-white p-1 rounded-md w-[200px] border-2  disabled:bg-gray-500 disabled:text-white">
                    {step === NotificationSteps.PUBLISH_NOTIFICATION ? <>&#128640; Publish </> : "Next"
                    }
                </button>
            </div>
        </div>
    )
}

export default EditNotificationWithSteps