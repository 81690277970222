

const ToggleSwitch = ({ id, active, toggleActive }: { id: string, active: boolean, toggleActive: (id: string) => void }) => {
    return <div className="flex justify-center items-center">

        {active ?
            <div onClick={() => toggleActive(id)} className="w-14 h-7 flex items-center  rounded-full mx-3 px-1 bg-blue-700" >
                <div className="bg-white w-5 h-5 rounded-full shadow-md transform translate-x-7"></div>
            </div>
            :
            <div onClick={() => toggleActive(id)} className="w-14 h-7 flex items-center bg-gray-300 rounded-full mx-3 px-1">
                <div className="bg-white w-5 h-5 rounded-full shadow-md transform" ></div>
            </div>
        }
    </div>
}

export default ToggleSwitch;

