import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { INotification } from '../types';
import { NotificationAction } from './actions/notification.action.d';
import { allNotificationsReducer, initialNotificationState } from './reducers/notification.reducer';



export const NotificationContext = createContext<{ state: INotification, dispatch: Dispatch<NotificationAction> }>({
    state: initialNotificationState,
    dispatch: () => { }
});

const NotificationProvider = ({ children }: { children: ReactNode }) => {

    const [state, dispatch] = useReducer(allNotificationsReducer, initialNotificationState);

    return (
        <NotificationContext.Provider value={{ state, dispatch }}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider 