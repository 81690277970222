import { useContext } from 'react';
import { CounterNotificationTimeOptions, NotificationTypes } from '../../../constants/notification';
import { PlatformTypes } from '../../../constants/platform';
import { setNotificationFromStorage } from '../../../context/actions/notification.action';
import { NotificationContext } from '../../../context/NotificationContext';
import { ICounterNotification, IScriptNotification } from '../../../types'
import CounterNotification from '../../CounterNotification';
import EachNotification from '../../EachNotification'

const NotificationType = () => {

    const { state, dispatch } = useContext(NotificationContext);

    const dummyDate = new Date();
    dummyDate.setHours(dummyDate.getHours() - 1);
    const dummyData: IScriptNotification = {
        firstName: "Chris",
        state: "Florida",
        country: "US",
        messageText: {
            beforeText: "signed up for",
            fieldMapper: "Upgiant",
            afterText: ""
        },
        date: dummyDate.toString(),
        image: "/assets/icons/snowflake.svg",
        destination: ""
    }

    const dummyCounterNotification: ICounterNotification = {
        afterText: "people signed up for tnds",
        time: CounterNotificationTimeOptions.LAST_MONTH,
        images: ["/assets/icons/snowflake.svg"],
        total: 12345,
        beforeText: ""
    }

    const addStreamType = (event: NotificationTypes) => {
        const notification = { ...state };
        notification.type = event;
        dispatch(setNotificationFromStorage(notification))
    }


    return (
        <div className='my-2 border-2 p-2'>
            <h4 className='font-bold my-2'>
                Select a type
            </h4>
            <div className='flex flex-col md:flex-row justify-around'>
                <div onClick={() => addStreamType(NotificationTypes.EVENT_STREAM)} className={`flex items-center bg-[#F2F2F2] justify-around hover:cursor-pointer ${state.type === NotificationTypes.EVENT_STREAM ? "border-[#2F82FF] border-2" : ""}`}>
                    <h4 className='mr-2 text-[#2F82FF] p-2 font-extrabold'>
                        Stream
                    </h4>
                    <EachNotification data={dummyData} />
                </div>

                {
                    state.platform.type !== PlatformTypes.SHOPIFY &&
                    <div onClick={() => addStreamType(NotificationTypes.COUNTER)} className={`flex items-center m-2 bg-[#F2F2F2] justify-around hover:cursor-pointer ${state.type === NotificationTypes.COUNTER ? "border-[#2F82FF] border-2" : ""}`}>
                        <h4 className='mr-2 text-[#2F82FF] p-2 font-extrabold'>
                            Counter
                        </h4>
                        <div className='bg-white h-full rounded-lg flex items-center p-2'>
                            <CounterNotification {...dummyCounterNotification} />
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default NotificationType