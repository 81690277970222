import { IPlatformTypes } from "../types";
import isProd from "./fetch";




export const SUPPORT_EMAIL = "support@upgiant.com"

export const UNBOUNCE_REDIRECT_URL = isProd ? "https://api.upgiant.com/platform/unbounce" : "http://localhost:8000/platform/unbounce"
export const UNBOUNCE_API = "https://api.unbounce.com/oauth/authorize?response_type=code&client_id=6229f32f1c2a379ee7255614fca65e1407ae8a0e9ca41c0910b3feac8d00118a&redirect_uri=" + UNBOUNCE_REDIRECT_URL
export const SHOPIFY_APP_URL = "https://apps.shopify.com/upgiant-social-proof"

export const AWS_S3_BUCKET_URL = "https://upgiant.s3.us-west-1.amazonaws.com/"
export const THEME_EXTENSION_UUID = "c4a77d04-3f95-4c1b-8263-3f0b2513bbe6"
export const SHOPIFY_THEME_EXTENSION_URL = "/admin/themes/current/editor?context=apps&activateAppId=" + THEME_EXTENSION_UUID + "/app-embed"


export enum PlatformTypes {
    "SHOPIFY" = "SHOPIFY",
    "UNBOUNCE" = "UNBOUNCE",
    "KLAVIYO" = "KLAVIYO",
    "NONE" = "NONE"
}

export const Platforms = [{
    name: PlatformTypes.KLAVIYO,
    icon: "/assets/icons/klaviyo.svg",
}, {
    name: PlatformTypes.UNBOUNCE,
    icon: "/assets/icons/unbounce.svg",

}, {
    name: PlatformTypes.SHOPIFY,
    icon: "/assets/icons/shopify.svg"
}];


export const platformLists: IPlatformTypes[] = [{
    _id: "1",
    icon: "/assets/icons/unbounce.svg",
    text: "Generate social proof notifications for new Unbounce submission",
    enabled: true,
    name: PlatformTypes.UNBOUNCE
},
{

    _id: "2",
    icon: "/assets/icons/klaviyo.svg",
    text: "Generate social proof notifications for new Klaviyo events",
    enabled: true,
    name: PlatformTypes.KLAVIYO
},
{

    _id: "3",
    icon: "/assets/icons/shopify.svg",
    text: "Generate social proof notifications for Shopify purchases",
    enabled: true,
    name: PlatformTypes.SHOPIFY
}
]



