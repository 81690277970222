
import { isArray, isString } from 'lodash';
import { useEffect, useState } from 'react';
import axiosInstance, { validateAxios } from '../../../config/axios';
import { initialMapperState } from '../../../constants/notification';
import { AWS_S3_BUCKET_URL, PlatformTypes } from '../../../constants/platform';
import { getScriptNotificationFromMapper, getScriptNotificationFromMapperUnbounce } from '../../../lib/notification';
import { mapShopifyOrderFromData } from '../../../lib/platform';
import { IEditBackendNotification, IScriptNotification } from '../../../types';
import EachNotification from '../../EachNotification';
import Tooltip from '../../Tooltip';
import DataMapper from '../DataMapper';

const EditMapper = ({ state, dispatch }: IEditBackendNotification) => {

    const [error, setError] = useState("");
    const [data, setData] = useState<any | null>(null);
    const [actualNotification, setActualNotification] = useState<IScriptNotification>(initialMapperState)
    const [isIpChanged, setIsIpChanged] = useState(false);


    useEffect(() => {
        (async () => {
            const error = await validateAxios(fetchData);
            if (error) {
                setError(error)
            }
        })();
    }, []);


    useEffect(() => {
        if (state.mapper && data && state.platform.type !== PlatformTypes.SHOPIFY) {
            (async () => {
                if (state.platform.type === PlatformTypes.KLAVIYO) {
                    const scriptNotification = getScriptNotificationFromMapper(state, data);
                    setActualNotification(scriptNotification);
                }
                else {
                    const scriptNotification = await getScriptNotificationFromMapperUnbounce(state, data, actualNotification, isIpChanged);
                    setActualNotification(scriptNotification);
                    setIsIpChanged(false);
                }
            })()
        }
    }, [state, data]);


    const fetchData = async () => {
        const platform = state.platform.type.toLocaleLowerCase();
        const backendUrl = "/platform/" + platform + "/get-data";
        if (state.platform.type === PlatformTypes.KLAVIYO) {
            const { data } = await axiosInstance.post(backendUrl, {
                metricId: state.platform.metric,
                count: 1
            }, {
                headers: {
                    hash: state.platform.hash
                }
            });
            setData(data.data[0]);
            const notification = { ...state };
            notification.platformData = data.data[0];
            dispatch(notification);
        }
        else if (state.platform.type === PlatformTypes.UNBOUNCE) {
            const { data } = await axiosInstance.post(backendUrl, {
                metricId: state.platform.metric,
                offset: 0,
                limit: 3
            }, {
                headers: {
                    hash: state.platform.hash
                }
            });
            setData(data.leads?.[0]);

            const notification = { ...state };
            notification.platformData = data.leads[0]
            dispatch(notification);

        }
        else if (state.platform.type === PlatformTypes.SHOPIFY) {
            const { data } = await axiosInstance.post(backendUrl, {
                shop: state.platform.label,
                limit: 1,
            }, {
                headers: {
                    hash: state.platform.hash,
                }
            });
            const scriptNotification = mapShopifyOrderFromData(data, state.mapper);
            const mapper = { ...state.mapper };
            mapper.destination = "Product Url";
            mapper.messageText = scriptNotification.messageText;
            setData(scriptNotification);
            setActualNotification(scriptNotification);

            const notification = { ...state };
            notification.mapper = mapper;
            notification.platformData = scriptNotification
            dispatch(notification);
        }
    }

    const setFirstName = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.firstName = fields;
        dispatch(notification);
    }

    const setCity = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.city = fields;
        dispatch(notification);
    }

    const setState = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.state = fields;
        dispatch(notification);
    }

    const setCountry = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""

        const notification = { ...state };
        notification.mapper.country = fields;
        dispatch(notification);
    }

    const setIp = async (value: string | string[]) => {
        setIsIpChanged(true);
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : "";

        const notification = { ...state };
        notification.mapper.ip = fields;
        dispatch(notification);

    }

    const setMessageTextFieldMapper = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.messageText.fieldMapper = fields;
        dispatch(notification);
    }
    const setMessageTextBeforeText = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""

        const notification = { ...state };
        notification.mapper.messageText.beforeText = fields;
        dispatch(notification);
    }

    const setMessageTextAfterText = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.messageText.afterText = fields;
        dispatch(notification);
    }

    const setEventDate = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""

        const notification = { ...state };
        notification.mapper.date = fields;
        dispatch(notification);
    }

    const setEventImage = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.image = fields;
        dispatch(notification);
    }

    const setDestination = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const notification = { ...state };
        notification.mapper.destination = fields;
        dispatch(notification);
    }


    const handleInputImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (files) {
            const allFiles = Object.entries(files).map((f) => f[1]);
            if (allFiles.length < 11) {
                const formData = new FormData();
                for (const f of allFiles) {
                    formData.append("images", f);
                }
                try {
                    const response = await axiosInstance.post("/upload", formData, {
                        headers: {
                            hash: state.platform.hash
                        }
                    });
                    const images = response.data.map((d: any) => AWS_S3_BUCKET_URL + d.key);
                    const notification = { ...state };
                    notification.images = images;
                    dispatch(notification);
                }
                catch {
                    console.log("Error uploading images to the backend");
                }
            }
        }
    }

    return (
        <div>
            <h2 className='font-bold text-xl'>
                Notifications, Mapper
            </h2>
            <div className='flex flex-col'>
                <div className='max-w-fit my-4'>
                    <EachNotification data={actualNotification} />
                </div>


                <div className='p-2 m-2 rounded max-w-fit border-lg border-2'>
                    <span className='bg-white text-[#A020f0] rounded-full p-2'>
                        &#8505;
                    </span>
                    ️ You can input manual data by scrolling to the bottom on every dropdown option.
                </div>

                {state.platform.type === PlatformTypes.SHOPIFY ? <div className='mx-4'>

                    <div className='flex  items-center my-2'>
                        <label >
                            <Tooltip text="Write the message before/after the given product name" />
                            Message Text
                        </label>
                        <div className='ml-2'>
                            <div className='flex justify-around items-center'>
                                <input disabled value={state.mapper.messageText.beforeText} onChange={(e) => setMessageTextBeforeText(e.target.value)} className='border-2 p-2 rounded-lg' placeholder='before text ' />
                                <div className=' mx-2 text-center font-bold italic' style={{
                                    color: state.style.titleColor
                                }} >
                                    {actualNotification.messageText.fieldMapper}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center my-2'>
                        <label>
                            <Tooltip text="Where do you want to go if the user click on the notification?" />
                            Destination Url
                        </label>
                        <div className='ml-2 flex-grow'>
                            <DataMapper selectedValue={state.mapper.destination} onChange={setDestination} data={{ "Product Url": "URL" }} />
                        </div>
                    </div>
                </div> :

                    <>
                        <div className='flex items-center my-2'>
                            <label className='w-40'>
                                <Tooltip text="Map the first name of your data to first name" />
                                First Name
                            </label>
                            <div className='ml-2 flex-grow'>
                                <DataMapper selectedValue={state.mapper.firstName} onChange={setFirstName} data={data} />
                            </div>
                        </div>
                        {state.platform.type === PlatformTypes.KLAVIYO ?
                            <>
                                <div className='flex items-center my-2'>
                                    <label className='w-40'>
                                        <Tooltip text="Map the city of your data to city" />
                                        City
                                    </label>
                                    <div className='ml-2 flex-grow'>
                                        <DataMapper selectedValue={state.mapper.city} onChange={setCity} data={data} />
                                    </div>
                                </div>
                                <div className='flex items-center my-2'>
                                    <label className='w-40'>
                                        <Tooltip text="Map the state/region of your data to region" />
                                        State
                                    </label>
                                    <div className='ml-2 flex-grow'>
                                        <DataMapper selectedValue={state.mapper.state} onChange={setState} data={data} />
                                    </div>
                                </div>
                                <div className='flex items-center my-2'>
                                    <label className='w-40'>
                                        <Tooltip text="Map the country of your data to country" />
                                        Country
                                    </label>
                                    <div className='ml-2 flex-grow'>
                                        <DataMapper selectedValue={state.mapper.country} onChange={setCountry} data={data} />
                                    </div>
                                </div>
                            </>
                            :
                            <div className='flex items-center my-2'>
                                <label className='w-40'>
                                    <Tooltip text="Map the ip address of your data to ip address" />
                                    IP Address
                                </label>
                                <div className='ml-2 flex-grow'>
                                    <DataMapper selectedValue={state.mapper.ip} onChange={setIp} data={data} />
                                </div>
                            </div>
                        }
                        <div className='flex items-center my-2'>
                            <label className='w-40'>
                                <Tooltip text="Write the message text. Map any data using the dropdown in the middle" />
                                Message Text
                            </label>
                            <div className='ml-2 flex-grow'>
                                <div className='flex justify-between items-center'>
                                    <input value={state.mapper.messageText.beforeText} onChange={(e) => setMessageTextBeforeText(e.target.value)} className='border-2 p-2 rounded-lg' placeholder='purchased ...' />
                                    <div className='flex-grow mx-2' >
                                        <DataMapper selectedValue={state.mapper.messageText.fieldMapper} onChange={setMessageTextFieldMapper} data={data} />
                                    </div>
                                    <input value={state.mapper.messageText.afterText} onChange={(e) => setMessageTextAfterText(e.target.value)} className='border-2 p-2 rounded-lg' placeholder='... from upgiant' />
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center my-2'>
                            <label className='w-40'>
                                <Tooltip text="Map the event date" />
                                Event Date
                            </label>
                            <div className='ml-2 flex-grow'>
                                <DataMapper selectedValue={state.mapper.date} onChange={setEventDate} data={data} />
                            </div>
                        </div>
                        <div className='flex items-center my-2'>
                            <label className='w-40'>
                                <Tooltip text="Map an image or upload images that picks random image on round robin fashion" />
                                Event Image
                            </label>
                            <div className='ml-2 flex-grow'>
                                <div>
                                    <DataMapper selectedValue={state.mapper.image} onChange={setEventImage} data={data} />
                                    <div className='m-2 text-center'>
                                        OR
                                    </div>
                                    <input onChange={handleInputImages} className='m-2' type="file" multiple accept="image/*" />
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center my-2'>
                            <label className='w-40'>
                                <Tooltip text="Where do you want to go if the user click on the notification?" />
                                Destination Url
                            </label>
                            <div className='ml-2 flex-grow'>
                                <DataMapper selectedValue={state.mapper.destination} onChange={setDestination} data={data} />
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default EditMapper