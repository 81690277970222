
export enum SubscriptionSource {
    STRIPE = "STRIPE",
    SHOPIFY = "SHOPIFY"
}

export interface IUser {
    _id: string;
    firstName: string;
    accessToken: string;
    refreshToken: string;
    stripeCustomerId?: string;
    stripePaymentStatus?: string;
    subscriptionId?: string;
    subscriptionType?: string;
    subscriptionEndDate?: string;
    subscriptionCancelAtPeriodEnd?: boolean;
    subscriptionSource?: SubscriptionSource;
    subscriptionStatus: string;
}



