import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Navbar from "../../../components/Navbar"
import Platform from "../../../components/Platform"
import Sidebar from "../../../components/Sidebar"
import Topbar from "../../../components/Topbar"
import axiosInstance, { validateAxios } from "../../../config/axios"
import { IPlatform } from "../../../types/platform"



const NotificationList = () => {

    const [error, setError] = useState("");
    const [existingPlatforms, setExistingPlatforms] = useState<IPlatform[]>([]);
    const navigate = useNavigate();

    const getPlatforms = async () => {
        const { data } = await axiosInstance.get<IPlatform[]>("/platform/");
        setExistingPlatforms(data);
    }

    useEffect(() => {
        (async () => {
            let error = await validateAxios(getPlatforms);
            if (error) {
                setError(error);
                return
            }
        })();
    }, []);




    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="flex flex-col ml-10 my-10">
                        <div className="flex justify-between">
                            <h2 className="text-[24px]">
                                Notifications List
                            </h2>
                        </div>

                        {existingPlatforms.length === 0 && <div className="m-2 font-bold">
                            You haven't added any platforms yet. Click on <button onClick={() => navigate("/create")} className="mx-1 border-2 p-2">+ New Notification</button> to start creating a new notification.
                        </div>}
                        <div className="flex flex-col">
                            {existingPlatforms.map((platform) => {
                                return <Platform data={platform} key={platform._id} />
                            })}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationList