
import { isArray } from 'lodash';
import { useContext } from 'react';
import Select from "react-select";
import { PlatformTypes } from '../../../constants/platform';
import { setDataFilters } from '../../../context/actions/notification.action';
import { NotificationContext } from '../../../context/NotificationContext';
import { IDataFilter, IEditBackendNotification } from '../../../types';
import Tooltip from '../../Tooltip';
import DataMapper from '../DataMapper';

interface IEditFilter extends IEditBackendNotification {
    id: string;
    options: { label: string, value: string }[];
    hasRemove?: boolean;
    removeFilter: (id: string) => void;
}

const EditFilter = ({ id, options, removeFilter, hasRemove = true, state, dispatch }: IEditFilter) => {



    const addFieldMapper = (val: string | string[]) => {
        if (isArray(val)) {
            const valueString = val.join("->");
            const existingFilter = state.dataFilters.find((filter) => filter.id === id);
            let allFilters: IDataFilter[] = [];
            if (existingFilter) {
                existingFilter.fieldMapper = valueString;
                allFilters = state.dataFilters.map((filter) => {
                    if (filter.id === existingFilter?.id) {
                        return existingFilter
                    }
                    return filter
                })
            }
            else {
                allFilters.push({
                    id,
                    fieldMapper: valueString,
                    include: false,
                    exclude: false,
                    text: ""
                })
            }
            const notification = { ...state };
            notification.dataFilters = allFilters;
            dispatch(notification);
        }
    }


    const onSelectOption = (option: string) => {

        const existingFilter = state.dataFilters.find((filter) => filter.id === id);
        let allFilters: IDataFilter[] = [];
        if (existingFilter) {
            if (option === "include") {
                existingFilter.include = true;
                existingFilter.exclude = false
            }
            else if (option === "exclude") {
                existingFilter.exclude = true;
                existingFilter.include = false;
            }

            allFilters = state.dataFilters.map((filter) => {
                if (filter.id === existingFilter?.id) {
                    return existingFilter
                }
                return filter
            })
            const notification = { ...state };
            notification.dataFilters = allFilters;
            dispatch(notification);
        }
    }

    const onInput = (value: string) => {

        const existingFilter = state.dataFilters.find((filter) => filter.id === id);
        let allFilters: IDataFilter[] = [];
        if (existingFilter) {
            existingFilter.text = value;
            allFilters = state.dataFilters.map((filter) => {
                if (filter.id === existingFilter?.id) {
                    return existingFilter
                }
                return filter
            })
        }
        const notification = { ...state };
        notification.dataFilters = allFilters;
        dispatch(notification);
    }


    const selectedFilter = state.dataFilters.find((f) => f.id === id);
    const includeOrExclude = selectedFilter?.exclude ? "exclude" : selectedFilter?.include ? "include" : undefined;

    const platformData = state.platform.type === PlatformTypes.SHOPIFY ? { "Product Name": "Product" } : state.platformData;

    return (
        <div className="border-2 p-2 rounded-lg my-2 bg-white mr-2">
            {hasRemove && <div className='flex justify-end'>
                <button onClick={() => removeFilter(id)} className='border-2 bg-[#000] text-white p-2 rounded-lg'>
                    Remove
                </button>
            </div>
            }
            <div className="mb-5">
                <div className="text-uppercase my-2 text-lg font-bold">
                    Type<Tooltip text="Choose the type of filter" />
                </div>
                <Select onChange={(e) => onSelectOption(e?.value || "")} options={options} value={options.find((f) => f.value === includeOrExclude)} />
            </div>
            <h2 className='font-bold text-lg my-2'>
                Field <Tooltip text="Choose the data field that you want to filter" />
            </h2>
            <DataMapper selectedValue={selectedFilter?.fieldMapper || ""} onChange={addFieldMapper} data={platformData} includeCustom={false} />

            <div className="text-uppercase  text-lg font-bold my-5">
                Input < Tooltip text="only show data that includes/excludes the following text" />
            </div>
            <input value={selectedFilter?.text} onChange={e => onInput(e.target.value)} className='border-2 p-2 rounded-lg w-[100%]' placeholder='eg: T-Shirt' />
        </div>
    )
}

export default EditFilter