import { useEffect, useState } from "react"
import Navbar from "../../components/Navbar"
import Sidebar from "../../components/Sidebar"
import Topbar from "../../components/Topbar"
import axiosInstance from "../../config/axios"
import { platformLists, PlatformTypes, SHOPIFY_APP_URL, SHOPIFY_THEME_EXTENSION_URL } from "../../constants/platform"
import { IPlatformTypes } from "../../types"
import { IPlatform } from "../../types/platform"

import Select from 'react-select'

const InstallScript = () => {

    const [platforms, setPlatforms] = useState<IPlatform[]>([]);

    const [selectedPlatform, setSelectedPlatform] = useState<IPlatform | undefined>(undefined);

    useEffect(() => {
        getPlatforms();
    }, [])

    const getPlatforms = async () => {
        const { data } = await axiosInstance.get<IPlatform[]>("/platform/");
        setPlatforms(data);
    }


    return (
        <div>
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="mx-5 my-2">
                        <h2 className="font-bold text-xl my-4">
                            Install Script for your platform
                        </h2>
                        <p className="font-bold text-sm">
                            Why is this necessary?
                        </p>
                        <p className="italic text-sm">
                            We use the the script to show the notification. Adding script to your platform is <span className="text-[#ff0000]">MANDATORY.</span>
                        </p>
                    </div>


                    {platformLists.map((platform: IPlatformTypes) => {
                        return <div key={platform.text} className={`flex flex-col justify-center mb-2 mx-2 bg-white border-2 rounded-lg p-1 mr-2 ${platform.enabled ? "" : "opacity-25"}`} >
                            <div className="flex items-center">
                                <img src={platform.icon} alt="icon" className="h-16 w-16" />
                                <p className="ml-2">{platform.name}</p>
                            </div>
                            {platform.name === PlatformTypes.SHOPIFY ?
                                <ol className="list-decimal mx-8 my-4">
                                    <li>
                                        Install the <a className="text-[#0000ff]" target="_blank" href={SHOPIFY_APP_URL}>app</a> from App Store
                                    </li>

                                    <div className="my-2 flex items-center">
                                        <div className="mr-2 font-extrabold">
                                            Choose your SHOPIFY platform to get the link. Click on it and activate the app!.
                                        </div>
                                    </div>

                                    <Select onChange={(e) => setSelectedPlatform(platforms.find((p) => p.hash === e?.value))} className="w-full" options={platforms.filter((x) => x.type === platform.name).map((x) => ({ label: x.label, value: x.hash }))} />

                                    {
                                        selectedPlatform && selectedPlatform.type === PlatformTypes.SHOPIFY &&
                                        <li className="my-2">
                                            Going to the link
                                            <a href={"https://" + selectedPlatform?.label + SHOPIFY_THEME_EXTENSION_URL} className="mx-2 underline text-[#0000ff]">
                                                {"https://" + selectedPlatform?.label + SHOPIFY_THEME_EXTENSION_URL}
                                            </a>

                                        </li>
                                    }

                                </ol>
                                :
                                <ol className="list-decimal mx-4">
                                    <div className="my-2">
                                        Ask any of your developers to inject following script to every pages where you want to display the notification.
                                    </div>

                                    <div className="my-2 flex items-center">
                                        <div className="mr-2 font-extrabold">
                                            Choose your {platform.name} platform to get the script
                                        </div>
                                    </div>

                                    <Select onChange={(e) => setSelectedPlatform(platforms.find((p) => p.hash === e?.value))} className="w-full" options={platforms.filter((x) => x.type === platform.name).map((x) => ({ label: x.label, value: x.hash }))} />
                                    {
                                        selectedPlatform?.hash && selectedPlatform.type === platform.name &&
                                        <pre>
                                            <code className="bg-[#eee] border-2 border-[#999] block p-5">
                                                {`<script async defer src='https://cdn.upgiant.com/app-1.0.0.js' data-hash="${selectedPlatform?.hash}"></script>`}
                                            </code>
                                        </pre>
                                    }
                                </ol>
                            }
                        </div>
                    })}


                </div>
            </div>
        </div>
    )
}

export default InstallScript


