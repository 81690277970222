import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateAxios } from "../../config/axios";
import { ACCESS_TOKEN_LOCAL_STORAGE } from "../../constants/auth";
import { BACKEND_URL, GOOGLE_SIGN_IN_URL } from "../../constants/fetch";
import { login } from "../../context/actions/auth.action";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { authDispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isMakingPlatform, setIsMakingPlatform] = useState(false);


    useEffect(() => {
        const queries = new URLSearchParams(location.search);
        const redirectTo = queries.get("redirectTo") || "";
        const type = queries.get("type") || "";
        const accessToken = queries.get("type") || "";
        if (redirectTo && type === "SHOPIFY" && accessToken) {
            setIsMakingPlatform(true);
        }
    }, [location.search])


    useEffect(() => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE);
        if (accessToken) {
            navigate("/")
            return
        }
    }, [])

    useEffect(() => {
        if (location.search) {
            let pathToRedirect = "/";
            const queries = new URLSearchParams(location.search);
            const accessToken = queries.get("accessToken") || "";
            const refreshToken = queries.get("refreshToken") || "";

            if (accessToken && refreshToken) {
                authDispatch(login({
                    accessToken,
                    refreshToken
                }));
                navigate(pathToRedirect);
            }
        }
    }, [location.search, authDispatch, navigate]);

    const loginCurrentUser = async () => {
        const response = await axios.post(BACKEND_URL + "/auth/login", {
            email,
            password
        });

        authDispatch(login({
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken
        }));

        const queries = new URLSearchParams(location.search);
        const redirectTo = queries.get("redirectTo") || "";

        if (redirectTo) {
            queries.delete("redirectTo");
            return navigate(redirectTo + `?${new URLSearchParams(queries).toString()}`)
        }

        navigate("/");
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        const error = await validateAxios(loginCurrentUser);
        if (error) {
            setError(error);
        }

    };


    return (
        <div className="bg-login-background h-screen w-[100%] flex items-center justify-center bg-cover bg-no-repeat">
            <div className="flex justify-center flex-col md:flex-row min-w-auto w-[100%]  max-w-[1080px] min-h-[606px] ">
                <div className="bg-login-background-2 md:w-[45%] lg:w-[40%] bg-cover bg-no-repeat flex flex-col p-[30px] md:p-[40px]">
                    <div className="font-weight-400 text-[44px] leading-[100%] uppercase bg-clip-text mb-[30px] md:mb-[40px] logo-spiral" >UPGIANT</div>
                    <div className="
                         font-weight-700 text-[35px] leading-[39px] text-white">Increase your conversion rates automatically</div>
                    <ul className="list-none my-8">
                        <li className="text-[21px] leading-[27px] text-white md:text-[18px]">✅  Easy setup, no coding required</li>
                        <li className="text-[21px] leading-[27px] text-white md:text-[18px]">✅  Anonymize events to showcase as automatic social proof</li>
                        <li className="text-[21px] leading-[27px] text-white md:text-[18px]">✅  ~33% average conv boost 🚀</li>
                    </ul>
                </div>
                <div className="bg-white w-[100%] md:w-[55%] lg:w-[60%] flex flex-col items-center justify-center p-[40px] md:p-[40px]">
                    {
                        isMakingPlatform &&
                        <div className="my-4 p-2">
                            <div className="my-2 bg-purple-800 text-white rounded-lg p-1">
                                Looks like you are trying to create a SHOPIFY PLATFORM.
                                After you login, if you do not see your shop while creating a notification. <br /> Do this:
                            </div>
                            <ol className="list-decimal p-1">
                                <li className="text-sm font-bold">
                                    Go to your Shopify Admin panel
                                </li>
                                <li className="text-sm font-bold">
                                    Click on our app
                                </li>
                            </ol>
                        </div>
                    }
                    <h1 className="w-[100%] font-[700] text-[36px] md:text-[42px] leading-[47px] text-center text-[#2b2b2b]  mb-5 md:my-0 ">Login</h1>
                    <form className="max-w-[385px] mx-0 my-auto" onSubmit={handleSubmit}>
                        <input required className="w-[100%] h-[40px] px-[10px] py-[14px] border-2 border-[#CFD4D9] bg-white font-weight-500 text-[16px] leading-[22px] text-[#2b2b2b] mb-[12px]" value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder='Email address' />
                        <input required className="w-[100%] h-[40px] px-[10px] py-[14px] border-2 border-[#CFD4D9] bg-white font-weight-500 text-[16px] leading-[22px] text-[#2b2b2b] mb-[12px]" value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" placeholder='Password' />

                        <button className="flex items-center justify-center bg-[#3351f5] rounded h-[50px] font-[700] w-[100%] text-white my-5" type='submit'>Sign In</button>
                        <div className="text-center my-3 text-[#c2c2c2] uppercase">or</div>


                        <a href={GOOGLE_SIGN_IN_URL} className='max-w-[375px] w-[100%] flex items-center justify-center h-[50px] text-white text-[21px] bg-[#DC4A33] ' >
                            Sign in with Google
                        </a>
                        {error && <p className="my-2 text-[#ff0000] text-center">
                            {JSON.stringify(error)}
                        </p>}
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Login