import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance, { validateAxios } from "../../config/axios";
import { PLAN_CONFIG, SubscriptionType } from "../../constants/plans";
import { ROUTING } from "../../constants/routing";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {

    const navigate = useNavigate();
    const settings = [
        {
            icon: "/assets/icons/notification.svg",
            text: "Notifications",
            onClick: () => navigate("/notifications")
        },
        {
            icon: "/assets/icons/analytics.svg",
            text: "Analytics",
            onClick: () => navigate("/analytics")

        },
        {
            icon: "/assets/icons/star.svg",
            text: "Manage Plan",
            onClick: () => navigate("/manage-plan")
        },
        {
            icon: "/assets/icons/script.svg",
            text: "Script",
            onClick: () => navigate("/install-script")
        },
        // {
        //     icon: "/assets/icons/platform.svg",
        //     text: "Platforms",
        //     onClick: () => navigate("/platforms")
        // },
        {
            icon: "/assets/icons/help.svg",
            text: "Get Help",
            onClick: () => navigate("/help")
        },
    ]


    const [totalUsed, setTotalUsed] = useState(0);

    const { authState } = useContext(AuthContext);
    const subscriptionType = authState.subscriptionType ? authState.subscriptionType : SubscriptionType.FREE;
    const config = subscriptionType === SubscriptionType.PRO ? PLAN_CONFIG["PRO"] : subscriptionType === SubscriptionType.ENTERPRISE ? PLAN_CONFIG["ENTERPRISE"] : PLAN_CONFIG["FREE"];

    const fetchTotalThisMonth = async () => {
        const { data } = await axiosInstance.get("/auth/analytics");
        setTotalUsed(data.count);
    }

    useEffect(() => {
        (async () => {
            await validateAxios(fetchTotalThisMonth)
        })();
    }, [])

    return (
        <div className="flex flex-col  m-4 bg-[#F6F8FC]">
            <button onClick={() => navigate("/")}>
                <img src="/assets/icons/icon.svg" alt="logo" className="mb-2" />
            </button>
            <button onClick={() => navigate("/create")} className="bg-[#C9E6FC] rounded-xl p-2 flex justify-center items-center my-4">
                <img src="/assets/icons/add.svg" alt="add" className="mr-2" />  New notification
            </button>
            <div className="flex flex-col">
                {settings.map((setting) => {
                    return <div onClick={setting.onClick} key={setting.text} className="flex items-center  mb-4 hover:cursor-pointer">
                        <img src={setting.icon} alt="add" className="mr-2 h-4 w-4" /> {setting.text}
                    </div>
                })}
            </div>

            <div className="flex flex-col my-6">
                <div className="font-semibold text-md">
                    {subscriptionType} Plan
                </div>

                <div className="flex justify-between text-sm my-1">
                    <div>
                        New Users
                    </div>
                    <div>
                        0-{config.users}
                    </div>
                </div>

                <div>
                    <input className="w-[100%]" type="range" id="points" name="points" min="0" max={config.users} onChange={() => { }} value={totalUsed} />
                </div>

                <div className="text-sm mb-2">
                    Monthly usage resets in 1 month
                </div>

                <a className="text-[#2F82FF] text-[10px]" href="/manage-plan">
                    Manage plan
                </a>

                {(subscriptionType === SubscriptionType.FREE || subscriptionType === SubscriptionType.PRO) &&
                    <button className="p-2 border-2 border-[#2F82FF] my-4">
                        <a href="/manage-plan " className="text-[#2F82FF]">
                            &#9889; Upgrade Plan
                        </a>
                    </button>}

            </div>
        </div >
    )
}

export default Sidebar