import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Nov", "Dec"];


const data = {
    labels,
    datasets: [
        {
            label: 'Total Notification Shown',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Total Notification Clicked',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};


const AnalyticsPage = () => {


    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="flex flex-col ml-10 my-10">
                        <div className="flex justify-between">
                            <h2 className="text-[24px] font-bold">Analytics</h2>
                        </div>

                        <div className="my-4">
                            Analytics <span className="text-sm italic mx-2">Under construction</span>


                            <Line options={options} data={data} />;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsPage;