import moment from "moment";
import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import { IScriptNotification } from "../../types";


const EachNotification = ({ data }: { data: IScriptNotification }) => {

    const { state } = useContext(NotificationContext)

    const { showLogo = true } = data;
    const stateOrCity = data.state === "<state>" ? data.city : data.state;

    return (
        <div className='flex p-4 bg-white border-2 rounded-xl'>
            <img src={data.image} alt="dummy-value" className="h-16 w-16" />
            <div className='flex flex-col ml-2'>
                <div className="flex">
                    <h2 className="font-bold">
                        {data.firstName}
                    </h2>
                    <div className="flex mx-2">
                        ({stateOrCity &&
                            <div className="mr-2">
                                {stateOrCity},
                            </div>
                        }
                        {data.country &&
                            <div>
                                {data.country}
                            </div>
                        })
                    </div>
                </div>

                <div className="flex flex-nowrap overflow-clip">
                    <div>
                        {data.messageText.beforeText}
                    </div>
                    <div style={{
                        color: state.style.titleColor
                    }} className="mx-1 underline">
                        {data.messageText.fieldMapper}
                    </div>
                    <div>
                        {data.messageText.afterText}
                    </div>
                </div>

                <div className="flex justify-around mt-4">
                    <div className="text-sm">
                        {moment(data.date).fromNow()}
                    </div>
                    {showLogo && <div className="ml-4 text-sm ">
                        <span className="bg-[#A020F0] rounded-full h-4 w-4 mr-1">
                            <span className="text-white p-1">
                                &#10004;
                            </span>
                        </span>
                        <span className="font-bold text-[#A020F0]">Upgiant</span>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default EachNotification