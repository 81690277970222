import { ACCESS_TOKEN_LOCAL_STORAGE, REFRESH_TOKEN_LOCAL_STORAGE } from '../../constants/auth';
import { PROFILE_LOCAL_STORAGE } from '../../constants/notification';
import { IUser } from '../../types/auth.d';
import { AuthAction } from './../actions/auth.action.d';

export const initialAuthState: IUser = {
    _id: "",
    firstName: "User",
    accessToken: "",
    refreshToken: "",
    stripeCustomerId: "",
    stripePaymentStatus: "",
    subscriptionId: "",
    subscriptionType: "",
    subscriptionEndDate: undefined,
    subscriptionCancelAtPeriodEnd: undefined,
    subscriptionStatus: "",
    subscriptionSource: undefined
}



export const allAuthReducer = (state: IUser, action: AuthAction): IUser => {
    switch (action.type) {
        case "LOGIN":
            const newState = { ...state, ...action.payload, loading: false };
            localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE, newState.accessToken);
            localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE, newState.refreshToken);
            return newState;
        case "SET_PROFILE": {
            const newState = { ...state, ...action.payload };
            localStorage.setItem(PROFILE_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }
        default:
            return state;
    }
}