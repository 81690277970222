import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AnalyticsPage from "../pages/analytics";
import HelpPage from "../pages/help";
import Login from "../pages/login";
import Notification from "../pages/notification";
import EditNotification from "../pages/notification/edit";
import NotificationList from "../pages/notification/list";
import ManagePlans from "../pages/plans";
import PlatformLists from "../pages/platforms";
import InstallScript from "../pages/script";
import Home from "./../pages/home";

const AllRoutes = () => {
    return <Router>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/edit-notification/:id" element={<EditNotification />} />
            <Route path="/install-script" element={<InstallScript />} />
            <Route path="/platforms" element={<PlatformLists />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/manage-plan" element={<ManagePlans />} />
            <Route path="/notifications" element={<NotificationList />} />
            <Route path="/create" element={<Notification />} />
            <Route path="*" element={<Home />} />
        </Routes>
    </Router>
}

export default AllRoutes;