

export enum SubscriptionType {
    FREE = "FREE",
    PRO = "PRO",
    ENTERPRISE = "ENTERPRISE"
}

export const PLAN_CONFIG = {
    "FREE": {
        users: 5000,
        features: [
            "Up to 5K unique users",
            "Limited Notification",
            "Unlimited impressions",
            "Unlimited Days"
        ],
        buttonText: "FREE",
        price: 0
    },
    "PRO": {
        users: 50000,
        features: [
            "Up to 50K unique users",
            "Unlimited impressions",
            "Unlimited Notifications",
            "Unlimited Days"
        ],
        buttonText: "Choose",
        price: 50
    },
    "ENTERPRISE": {
        users: 100000,
        features: [
            "Up to 100K unique users",
            "Unlimited impressions",
            "Unlimited Notifications",
            "Unlimited Days"
        ],
        buttonText: "Choose",
        price: 100
    }
}