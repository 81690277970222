import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"

const Navbar = () => {
    const { authState } = useContext(AuthContext);

    const logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/login"
    }
    return (
        <div className='flex h-12 justify-end items-center bg-white border-b-2 p-1 '>
            <div className="mr-2 flex ">
                <p className="mx-2">{authState.firstName}</p>
                <button onClick={logout}>
                    <img src='/assets/icons/logout.svg' alt="" className="h-6" />
                </button>
            </div>
        </div>
    )
}

export default Navbar