import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/index.css"
import Routes from "./routes"
import App from './App';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N6WBHM'
}
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App>
      <Routes />
    </App>
  </React.StrictMode>
);

