import { useContext, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import axiosInstance, { validateAxios } from "../../../config/axios";
import { platformLists, PlatformTypes } from "../../../constants/platform";
import { setPlatform } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";
import { initialNotificationState } from "../../../context/reducers/notification.reducer";
import useQuery from "../../../hooks/useQuery";
import { IPlatform } from "../../../types/platform.d";

const IconOption = (props: any) => {

    const { Option } = components;
    return <Option  {...props}>
        <div className="flex items-center" >
            <img
                className="h-8 w-8 mr-2"
                src={props.data.icon}
                alt={props.data.label}
            />
            {props.data.label}
        </div>
    </Option>
};

const ChoosePlatform = () => {
    const { state, dispatch } = useContext(NotificationContext);
    const [existingPlatforms, setExistingPlatforms] = useState<IPlatform[]>([]);
    const [authorizing, setAuthorizing] = useState(false);
    const query = useQuery();
    const ref = useRef<boolean>(true);

    const platformOptions = platformLists.filter((p) => p.enabled).map((platform,) => ({
        _id: platform._id,
        value: platform.name,
        label: platform.name,
        icon: platform.icon
    }));


    existingPlatforms.forEach((p) => {
        const option = platformLists.find((eachP) => eachP.enabled && eachP.name === p.type);
        if (!option) {
            return;
        }
        if (!platformOptions.find((platform) => platform.value === p.hash)) {
            platformOptions.push({
                _id: p._id,
                value: p.hash,
                label: p.label || p.hash,
                icon: option.icon
            })
        }
    })

    const defaultPlatform = platformOptions.find((p) => p._id === state.platform._id);

    const getPlatforms = async () => {
        const { data } = await axiosInstance.get<IPlatform[]>("/platform/");
        setExistingPlatforms(data);
    }

    useEffect(() => {
        (async () => {
            await validateAxios(getPlatforms);
        })();
    }, [])

    useEffect(() => {
        const type = query.get("type");
        const accessToken = query.get("accessToken");
        const label = query.get("shop") || "";
        const refreshToken = query.get("refresh_token") || "";
        if ((type === PlatformTypes.UNBOUNCE || type === PlatformTypes.SHOPIFY) && accessToken && (refreshToken || label) && ref.current) {
            (async () => {
                try {
                    setAuthorizing(true);
                    ref.current = false;
                    const { data } = await axiosInstance.post("/platform/create", {
                        accessToken,
                        refreshToken,
                        label,
                        type,
                    });

                    const option = platformLists.find((eachP) => eachP.enabled && eachP.name === data.type);
                    if (!option) {
                        return;
                    }
                    platformOptions.push({
                        _id: data._id,
                        value: data.hash,
                        label: data.hash,
                        icon: option.icon
                    });

                    dispatch(setPlatform(data));
                    window.location.href = "/create"
                }
                catch (err) {
                    console.log("Error while updating db")
                }
                finally {
                    setAuthorizing(false);
                }
            })();
        }
    }, [query])


    const choosePlatform = (_id: string) => {
        const existingPlatform = existingPlatforms.find((p) => p._id === _id);
        if (existingPlatform) {
            return dispatch(setPlatform(existingPlatform))
        }
        const platform = platformOptions.find((p) => p._id === _id);
        const newPlatform = { ...initialNotificationState.platform };
        newPlatform._id = _id;
        newPlatform.type = platform?.value as PlatformTypes;
        dispatch(setPlatform(newPlatform))
    }

    return (
        authorizing ?
            <div className="h-screen w-[100%] flex items-center justify-center">
                Authorizing ...
            </div>
            :
            <div>
                <h2 className="font-bold text-lg">
                    Choose app & event
                </h2>

                <div className="bg-white rounded-md mt-2 p-2">
                    <div className="flex flex-col">
                        Choose a platform:
                    </div>

                    <Select className="p-2 my-2"
                        value={defaultPlatform}
                        options={platformOptions}
                        onChange={(e) => choosePlatform(e?._id || "")}
                        components={{ Option: IconOption }} />
                </div>
            </div>
    )
}

export default ChoosePlatform