import { LoginAction, SetProfile, SetProfileAction } from "./auth.action.d";

export const login = (payload: { accessToken: string, refreshToken: string }): LoginAction => ({
    type: "LOGIN",
    payload
})

export const setProfile = (payload: SetProfile): SetProfileAction => ({
    type: "SET_PROFILE",
    payload
})