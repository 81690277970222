import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { SHOPIFY_BACKEND_URL } from "../../constants/fetch";
import { SubscriptionType } from "../../constants/plans";
import { PlatformTypes, SHOPIFY_APP_URL } from "../../constants/platform";
import { IPlatform } from "../../types/platform";


interface IModalProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>,
    paymentType: SubscriptionType,
    platforms: IPlatform[],
}

export default function Modal({ show, setShow, paymentType, platforms }: IModalProps) {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const hasShopify = platforms.find((platform) => platform.type === PlatformTypes.SHOPIFY);
        if (hasShopify) {
            setStep(1);
        }
    }, [platforms])

    const shopifyPaymentUrl = async (shop: string) => {
        setLoading(true);
        try {
            const { data } = await axios.post(SHOPIFY_BACKEND_URL + "/create-payment", {
                shop,
                plan: paymentType
            });

            const url = data?.body?.data?.appSubscriptionCreate?.confirmationUrl || "";
            if (url) {
                window.location.href = url;
            }
        }
        catch {
            setLoading(false);
        }
    }


    const renderComponentWithStep = () => {
        if (step === 0) {
            return <>
                <div className="font-bold text-xl">
                    <div className="flex items-center justify-center">
                        Are you connecting a Shopify Store <span className="mx-1">
                            <img alt="icon" src="/assets/icons/shopify.svg" width={20} height={20} />
                        </span> in the near future?
                    </div>
                </div>
                <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        className="bg-emerald-500  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex items-center justify-center"
                        type="button"
                        onClick={async () => {
                            if (step === 0) {
                                setStep(1);
                                return
                            }

                        }}
                    >
                        <div className="flex items-center justify-center">
                            Yes
                        </div>
                    </button>
                    <button
                        className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={async () => {
                            const { data } = await axiosInstance.post("/stripe/create-checkout-session", {
                                subscriptionType: paymentType
                            });
                            window.location.href = data.url;
                        }}
                    >
                        No
                    </button>
                </div>
            </>
        }
        return <div className="flex flex-col">
            {platforms.length === 0 ? <div>No Shopify apps installed. Please install our <a href={SHOPIFY_APP_URL}>app</a>. If anything is wrong, please contact us at <span className="text-bold italic">support@upgiant.com</span> </div> :
                <>
                    {platforms.map((platform) => {
                        return loading ? <div key={platform._id} className="
                                                            text-lg font-bold italic p-2 m-2 text-center"> loading... </div> : <button className="p-1 rounded-md b-2 m-2 hover:cursor-pointer text-white bg-[#A020F0] text-center " key={platform._id} onClick={() => shopifyPaymentUrl(platform.label!)}>
                            {platform.label}
                        </button>
                    })}
                </>}
        </div>
    }


    return (
        <>
            {show ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {step === 0 ? "Choose your payment type" : "Choose your store"}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => {
                                            setShow(false);
                                            const hasShopifyPlatform = platforms.find((platform) => platform.type === PlatformTypes.SHOPIFY);
                                            if (!hasShopifyPlatform) {
                                                setStep(0);
                                            }
                                            else {
                                                setStep(1);
                                            }
                                        }}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-1 flex-auto">
                                    <div className="my-4 text-slate-500 text-lg ">
                                        {renderComponentWithStep()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}