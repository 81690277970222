import { isString } from "lodash";
import axiosInstance from "../config/axios";
import { IBackendNotification, INotification, IScriptNotification } from "../types";
import { initialMapperState } from './../constants/notification';

export const getScriptNotificationFromMapper = (notification: INotification, data: any): IScriptNotification => {
    const { firstName, city, country, state, ip, messageText, date, image, destination } = notification.mapper;
    const { images } = notification;
    const randomImage = images?.[Math.floor(Math.random() * images.length)];
    const script: IScriptNotification = {
        firstName: getField(firstName, data) || initialMapperState.firstName,
        date: getField(date, data) || initialMapperState.date,
        image: getField(image, data) || randomImage || initialMapperState.image,
        messageText: {
            beforeText: messageText.beforeText || initialMapperState.messageText.beforeText,
            fieldMapper: getField(messageText.fieldMapper, data) || initialMapperState.messageText.fieldMapper,
            afterText: messageText.afterText || initialMapperState.messageText.afterText
        } || initialMapperState.messageText,
        state: getField(state, data) || initialMapperState.state,
        country: getField(country, data) || initialMapperState.country,
        ip: getField(ip, data) || initialMapperState.ip,
        city: getField(city, data) || initialMapperState.city,
        destination: getField(destination, data) || initialMapperState.destination,
    }

    return script;
}

export const getScriptNotificationFromMapperUnbounce = async (notification: INotification, data: any, currentData: IScriptNotification, isIpChanged = false): Promise<IScriptNotification> => {
    const { firstName, ip, messageText, date, image, destination } = notification.mapper;
    const { images } = notification;
    const randomImage = images?.[Math.floor(Math.random() * images.length)];
    const script: IScriptNotification = {
        firstName: getField(firstName, data) || initialMapperState.firstName,
        date: getField(date, data) || initialMapperState.date,
        image: getField(image, data) || randomImage || initialMapperState.image,
        messageText: {
            beforeText: messageText.beforeText || initialMapperState.messageText.beforeText,
            fieldMapper: getField(messageText.fieldMapper, data) || initialMapperState.messageText.fieldMapper,
            afterText: messageText.afterText || initialMapperState.messageText.afterText
        } || initialMapperState.messageText,
        ip: getField(ip, data) || initialMapperState.ip,
        destination: getField(destination, data) || initialMapperState.destination,
        state: currentData.state || initialMapperState.state,
        country: currentData.country || initialMapperState.country,
        city: currentData.city || initialMapperState.city,
    }
    if (isIpChanged) {
        const actualIp = getField(ip, data);
        const location = await getLocationFromIp(actualIp);
        if (location) {
            const { state, city, country } = location;
            script.state = state;
            script.city = city;
            script.country = country;
        }
    }
    return script;
}

export const getLocationFromIp = async (ip: string) => {
    try {
        const { data: location } = await axiosInstance.post("/utils/check-geo", {
            ip
        });
        const state = location?.["region"] || "";
        const city = location?.["city"] || "";
        const country = location?.["country"] || "";
        return {
            state, city, country
        }
    }
    catch {
        return null;
    }
}


export const getField = (field: string, data: any): string => {
    const getParsedValue = () => {

        try {
            if (!field) {
                return ""
            }
            const fieldMapperNames = field.split("->");
            let obj = { ...data };
            let inside = false;
            if (field.startsWith("UPGIANT_CUSTOM->")) {
                return field.split("->")[1];
            }
            for (const fieldMapper of fieldMapperNames) {
                obj = obj[fieldMapper]
                inside = true;
                if (!obj) {
                    return ""
                }
            }
            if (!inside) {
                return ""
            }

            if (Array.isArray(obj)) {
                return obj?.[0] || "";
            }
            return obj;
        }
        catch {
            return "Invalid Data";
        }
    }
    const val = getParsedValue();
    return isString(val) ? val : "Invalid Data";
}


export const mapCurrentNotificationToBackendCreate = (data: INotification) => {
    const enabledDataFilters = data.dataFilters.filter((filter) => filter.include || filter.exclude);

    const dataFilterMapper = enabledDataFilters.map((filter) => {
        const { id, ...remFilter } = filter;
        return {
            ...remFilter
        }
    });

    return {
        platformType: data.platform.type,
        type: data.type,
        image: data.mapper.image,
        maxNumberOfEvents: data.maxNumberOfEvents,
        style: data.style,
        dataFilters: dataFilterMapper,
        urlFilter: data.urlFilter,
        searchId: data.searchId,
        behaviors: data.behaviors,
        mapper: data.mapper,
        label: data.label,
        images: data.images,
        counterTime: data.counterTime
    }
}
export const mapCurrentNotificationToBackendEdit = (data: IBackendNotification) => {
    const enabledDataFilters = data.dataFilters.filter((filter) => filter.include || filter.exclude);

    const dataFilterMapper = enabledDataFilters.map((filter: any) => {
        const { id, _id, ...remFilter } = filter;
        return {
            ...remFilter
        }
    });

    return {
        image: data.mapper.image,
        dataFilters: dataFilterMapper,
        urlFilter: data.urlFilter,
        mapper: data.mapper,
        maxNumberOfEvents: data.maxNumberOfEvents,
        behaviors: data.behaviors,
        style: data.style,
        label: data.label,
        images: data.images,
        active: data.active,
        counterTime: data.counterTime
    }
}