import { initialMapperState } from "../constants/notification";
import { IMapper, IScriptNotification } from "../types";
import { getField } from "./notification";

export const mapShopifyOrderFromData = (data: any, mapper: IMapper): IScriptNotification => {
    try {

        const eachOrder = data.edges[0];
        const order = eachOrder.node;
        const eachLineItem = order.lineItems.edges?.[0];
        const orderItem = eachLineItem.node;
        const { firstName = "", countryCodeV2 = "", city = "", province = "" } = order?.billingAddress;
        const destination = mapper?.destination.includes("UPGIANT_CUSTOM->") ? getField(mapper.destination, order) : orderItem?.product.onlineStoreUrl || "";
        const requiredInfo = {
            uid: orderItem?.id || "",
            image: orderItem.image?.url || "",
            firstName: order.customer?.firstName || firstName || "Anonymous",
            state: order?.customer?.addresses?.[0]?.province || province || "",
            city: order?.customer?.addresses?.[0]?.city || city || "",
            country: order?.customer?.addresses?.[0]?.countryCodeV2 || countryCodeV2 || "",
            date: order["createdAt"] || "",
            messageText: {
                beforeText: mapper?.messageText.beforeText || "",
                fieldMapper: orderItem["name"],
                afterText: mapper?.messageText.afterText || ""
            },
            destination
        }
        return requiredInfo;
    }
    catch {
        return initialMapperState
    }
}