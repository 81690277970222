
let isProd = false;
if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    isProd = true;
}

export default isProd

export const BACKEND_URL = isProd ? "https://api.upgiant.com" : "http://localhost:8000";
export const FRONTEND_URL = isProd ? "https://upgiant.com" : "http://localhost:3000";
export const SIGN_UP_URL = "https://try.upgiant.com";
export const SHOPIFY_BACKEND_URL = isProd ? "https://platform.upgiant.com" : "http://localhost:8081";

export const GOOGLE_SIGN_IN_URL = BACKEND_URL + "/auth/google";
