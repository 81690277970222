import { useContext } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { setUrlFilter } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";
import { UrlFilterOption } from "../../../types";
import Tooltip from "../../Tooltip";


export interface IEachUrlFilter {
    id: string;
    selectedIncludeOption: {
        label: string;
        value: string;
    } | undefined,
    setInclude: (id: string, val: UrlFilterOption) => void;
    inputValue: string;
    addUrl: (id: string, val: string) => void;
    removeFilter: (id: string) => void;
}


const options = [{
    label: "Exact",
    value: "exact"
},
{
    label: "Containing",
    value: "containing"
},
{
    label: "Regex",
    value: "regex"
}
]

const EachUrlFilter = ({ id, selectedIncludeOption, setInclude, inputValue, addUrl, removeFilter }: IEachUrlFilter) => {

    return <>
        <div className="flex items-center mt-2 mx-4">
            <Select className="w-[150px]" value={selectedIncludeOption} onChange={(v) => setInclude(id, v?.value as UrlFilterOption)} options={options} />

            <input value={inputValue} onChange={(e) => addUrl(id, e.target.value)} className=" rounded-lg mx-3 border-2 p-2 w-[100%] my-3" placeholder="https://upgiant.com/..." />
        </div>
        <button className="mx-4 border-2 p-2 rounded-md" onClick={() => removeFilter(id)}>
            Remove
        </button>
    </>
}

const UrlFilter = () => {

    const { state, dispatch } = useContext(NotificationContext);



    const enableInclude = () => {
        const urlFilter = state.urlFilter;
        urlFilter.include.enable = !urlFilter.include.enable;
        urlFilter.showOnAll = false;
        dispatch(setUrlFilter(urlFilter))
    }

    const setInclude = (id: string, val: UrlFilterOption) => {
        const urlFilter = state.urlFilter;
        const selectedOption = urlFilter.include.options.find((f) => f.id === id);
        selectedOption!.option = val;
        urlFilter.showOnAll = false;
        urlFilter.include.enable = true;
        dispatch(setUrlFilter(urlFilter))
    }

    const addIncludeUrl = (id: string, val: string) => {
        const urlFilter = state.urlFilter;
        const selectedOption = urlFilter.include.options.find((f) => f.id === id);
        selectedOption!.text = val;
        urlFilter.include.enable = true;
        dispatch(setUrlFilter(urlFilter))
    }

    const enableExclude = () => {
        const urlFilter = state.urlFilter;
        urlFilter.exclude.enable = !urlFilter.exclude.enable;
        urlFilter.showOnAll = false;
        dispatch(setUrlFilter(urlFilter))
    }

    const setExclude = (id: string, val: UrlFilterOption) => {
        const urlFilter = state.urlFilter;
        const selectedOption = urlFilter.include.options.find((f) => f.id === id);
        selectedOption!.option = val;
        urlFilter.showOnAll = false;
        urlFilter.exclude.enable = true;
        dispatch(setUrlFilter(urlFilter))
    }


    const addExcludeUrl = (id: string, val: string) => {
        const urlFilter = state.urlFilter;
        const selectedOption = urlFilter.include.options.find((f) => f.id === id);
        selectedOption!.text = val;
        urlFilter.exclude.enable = true;
        dispatch(setUrlFilter(urlFilter))
    }

    const setAllFilter = () => {
        const urlFilter = { ...state.urlFilter };
        urlFilter.showOnAll = !state.urlFilter.showOnAll;
        urlFilter.include.enable = false;
        urlFilter.exclude.enable = false;
        dispatch(setUrlFilter(urlFilter))
    }


    const addMoreIncludeFilters = () => {
        const newFilter = {
            id: v4(),
            option: "exact" as UrlFilterOption,
            text: "",
        }
        const urlFilter = { ...state.urlFilter };
        urlFilter.include.options.push(newFilter);
        dispatch(setUrlFilter(urlFilter))
    }

    const addMoreExcludeFilters = () => {
        const newFilter = {
            id: v4(),
            option: "exact" as UrlFilterOption,
            text: "",
        }
        const urlFilter = { ...state.urlFilter };
        urlFilter.exclude.options.push(newFilter);
        dispatch(setUrlFilter(urlFilter))
    }

    const removeFilter = (id: string) => {
        const urlFilter = { ...state.urlFilter };
        const remUrlFilterOptions = urlFilter.include.options.filter((f) => f.id !== id);
        const remUrlFilterOptionsExclude = urlFilter.exclude.options.filter((f) => f.id !== id);
        urlFilter.include.options = remUrlFilterOptions;
        urlFilter.exclude.options = remUrlFilterOptionsExclude;
        dispatch(setUrlFilter(urlFilter))
    }

    return (
        <div>
            <h2 className='font-bold text-xl'>
                Notifications, Targetting
            </h2>

            <div className="font-bold my-4">
                Where to Display?
            </div>
            <div>
                <input checked={state.urlFilter.include.enable} onChange={enableInclude} type="checkbox" /> Only show on certain urls
                <Tooltip text="Show data only on following url" />
            </div>
            <div>
                {state.urlFilter.include.options.map((urlFilter) => {
                    const selectedIncludeOption = options.find((f) => f.value === urlFilter.option);
                    return <EachUrlFilter key={urlFilter.id} id={urlFilter.id} selectedIncludeOption={selectedIncludeOption} setInclude={setInclude} inputValue={urlFilter.text} addUrl={addIncludeUrl} removeFilter={removeFilter} />
                })}
            </div>
            <button onClick={addMoreIncludeFilters} className="bg-black text-white p-2 rounded-md mx-4 mt-2">
                Add More Filters
            </button>

            <div className="font-bold my-4">
                Where to exclude?
            </div>
            <div>
                <input checked={state.urlFilter.exclude.enable} onChange={enableExclude} type="checkbox" /> Don't show on some urls
                <Tooltip text="DO NOT show the data on following url" />
            </div>
            <div>
                {state.urlFilter.exclude.options.map((urlFilter) => {
                    const selectedIncludeOption = options.find((f) => f.value === urlFilter.option);
                    return <EachUrlFilter key={urlFilter.id} id={urlFilter.id} selectedIncludeOption={selectedIncludeOption} setInclude={setExclude} inputValue={urlFilter.text} addUrl={addExcludeUrl} removeFilter={removeFilter} />
                })}

            </div>
            <button onClick={addMoreExcludeFilters} className="bg-black text-white p-2 rounded-md mx-4 mt-2">
                Add More Filters
            </button>

            <div className="font-bold my-4">
                Select All
            </div>
            <div>
                <input checked={state.urlFilter.showOnAll} onChange={setAllFilter} type="checkbox" /> Show on all pages
                <Tooltip text="Show the data from any urls" />
            </div>
        </div>
    )
}

export default UrlFilter