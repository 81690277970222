import moment from "moment"
import { useEffect, useState } from "react"
import Navbar from "../../components/Navbar"
import Sidebar from "../../components/Sidebar"
import Topbar from "../../components/Topbar"
import axiosInstance from "../../config/axios"
import { platformLists, PlatformTypes, SHOPIFY_THEME_EXTENSION_URL } from "../../constants/platform"
import { IPlatform } from "../../types/platform"

const PlatformLists = () => {

    const [platforms, setPlatforms] = useState<IPlatform[]>([]);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        (async () => {
            getPlatforms();
        })();
    }, [])

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
                clearTimeout(timeout);
            }, 100);
        }
    }, [copied])


    const getPlatforms = async () => {
        const { data } = await axiosInstance.get<IPlatform[]>("/platform/");
        setPlatforms(data);
    }

    const copyToClipBoard = async (hash: string) => {
        const code = `<script src='https://cdn.upgiant.com/app-1.0.0.js' data-hash='${hash}'> </script>`
        await navigator.clipboard.writeText(code);
        setCopied(true);
    }


    return (
        <div>
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="mx-5 my-2">
                        <h2 className="font-bold text-xl my-4">
                            Your Platforms
                        </h2>
                    </div>

                    {platformLists.map((option) => {
                        const filteredPlatforms = platforms.filter((p) => p.type === option.name);
                        const userHasPlatform = filteredPlatforms.find((p) => p.type === option.name);
                        if (!userHasPlatform) {
                            return null;
                        }
                        return <div className="flex flex-col mx-4" >
                            <div className="flex">
                                <img
                                    className="h-8 w-8 mr-2"
                                    src={option.icon}
                                    alt={option.name}
                                />
                                {option.name}
                            </div>


                            {filteredPlatforms.length === 0 && <div>
                                You haven't added any platforms yet. Create a notification with either shopify, unbounce or klaviyo to add a platform
                            </div>}

                            {filteredPlatforms.map((p) => {
                                const platformCreatedDate = p._id.toString().substring(0, 8);
                                const date = new Date(parseInt(platformCreatedDate, 16) * 1000);
                                const beforeNow = moment(date).fromNow()
                                if (p.type === PlatformTypes.SHOPIFY) {
                                    const url = "https://" + p.label + SHOPIFY_THEME_EXTENSION_URL;
                                    return <div key={p._id} className="m-2 p-2  border-2 ">
                                        Double check if upgiant theme has been added.
                                        Click on the link to activate the script
                                        <a target={"_blank"} rel="noreferer noopener" href={url} className="mx-2 underline text-[#0000ff]">
                                            here
                                        </a>. You only need to do this once.
                                    </div>
                                }
                                return <div key={p._id} className="m-2 p-2 max-w-fit flex items-center rounded-lg">
                                    <button onClick={() => copyToClipBoard(p.hash)} className={`mx-2 ${copied ? "translate-y-[-5px] duration-200" : ""} `}>
                                        <img className="h-8 w-8" src="/assets/icons/copy.svg" alt="copy" />
                                    </button>
                                    <div>
                                        <pre>
                                            <code className="bg-[#eee] border-2 rounded-lg border-[#999] block p-5 max-w-fit">
                                                &#60;script async defer src='https://cdn.upgiant.com/app-1.0.0.js' data-hash='{p.hash}' &#62; &#60;/script&#62;
                                            </code>
                                        </pre>
                                        <p className="text-sm italic">
                                            Created at: <span className="font-bold">{beforeNow}</span>
                                        </p>
                                    </div>
                                </div>
                            })}

                        </div>
                    })}


                </div>
            </div>
        </div>
    )
}

export default PlatformLists