import { v4 } from "uuid";
import { INotification, IScriptNotification } from "../types";
import { PlatformTypes } from "./platform";

export const fontTypes = ["DM Sans", "Roboto", "Sans serif"];
export const fontStyle = ["Bold", "Normal", "Italic"];
export const fontSize = ["16", "18", "20"];
export const languageOptions = ["English"];
export const transitionEffect = ["Disappear", "Stacking"];


export const ONGOING_NOTIFICATION_LOCAL_STORAGE = "ONGOING_NOTIFICATION_LOCAL_STORAGE";
export const PROFILE_LOCAL_STORAGE = "USER_PROFILE";

export const COUNTER_NOTIFICATION_COUNT = 12345;

// export type ITimeOptions = "since yesterday" | "since last week" | "since last month" | "since last 3 months" | "since last 6 months";
export enum CounterNotificationTimeOptions {
    LAST_DAY = "since yesterday",
    LAST_WEEK = "since last week",
    LAST_MONTH = "since last month",
    LAST_2_MONTH = "since last 2 months",
    LAST_3_MONTHS = "since last 3 months",
    LAST_6_MONTHS = "since last 6 months",
}

export enum NotificationTypes {
    EVENT_STREAM = "EVENT_STREAM",
    COUNTER = "COUNTER"
}

export enum NotificationSteps {
    "CHOOSE_PLATFORM" = "CHOOSE_PLATFORM",
    "ACCOUNT_AUTHORIZE" = "ACCOUNT_AUTHORIZE",
    "CHOOSE_SPECIFIC_METRIC" = "CHOOSE_SPECIFIC_METRIC",
    "NOTIFICATION_TYPE" = "NOTIFICATION_TYPE",
    "NOTIFICATION_STYLE" = "NOTIFICATION_STYLE",
    "MAPPER" = "MAPPER",
    "DATA_FILTER" = "DATA_FILTER",
    "URL_FILTER" = "URL_FILTER",
    "BEHAVIOR_FILTER" = "BEHAVIOR_FILTER",
    "LABEL_NOTIFICATION" = "LABEL_NOTIFICATION",
    "PUBLISH_NOTIFICATION" = "PUBLISH_NOTIFICATION",
}


export const initialMapperState: IScriptNotification = {
    firstName: "<first_name>",
    date: "2022/9/19 3:54 PM",
    image: "/apple-touch-icon.png",
    messageText: {
        beforeText: "",
        fieldMapper: "",
        afterText: ""
    },
    state: "<state>",
    country: "<country>",
    destination: "<destination>",
}


export const initialNotificationStateWithoutData: INotification = {
    platform: {
        _id: "",
        user: "",
        type: PlatformTypes.NONE,
        hash: "",
        accessToken: "",
        refreshToken: "",
        authCode: ""
    },
    searchId: "",
    customDisable: false,
    type: NotificationTypes.EVENT_STREAM,
    style: {
        titleColor: "#FF8268",
        font: {
            size: 16,
            type: "DM Sans",
            style: "Normal"
        },
        position: "Bottom Left",
        language: "English",
        transition: "Disappear"
    },
    mapper: {
        firstName: "",
        city: "",
        state: "",
        country: "",
        ip: "",
        messageText: {
            beforeText: "",
            fieldMapper: "",
            afterText: ""
        },
        date: "",
        image: "",
        destination: ""
    },
    dataFilters: [],
    urlFilter: {
        include: {
            enable: false,
            options: [
                {
                    option: "exact",
                    text: "",
                    id: v4()
                }
            ]
        },
        exclude: {
            enable: false,
            options: [
                {
                    option: "exact",
                    text: "",
                    id: v4()
                }
            ]
        },
        showOnAll: false
    },
    behaviors: {
        showOnLoad: true,
        rules: {
            enable: false,
            showAfterTime: "",
            showAfterPageScroll: ""
        },
        rotateAfterTime: 5
    },
    maxNumberOfEvents: 5,
    label: "",
    platformData: null,
    images: []
};