
import { useContext } from "react";
import { PlatformTypes, SHOPIFY_APP_URL, UNBOUNCE_API } from "../../../constants/platform";
import { authorizePlatform } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";


const AccountAuthorize = () => {
    const { state, dispatch } = useContext(NotificationContext);

    const addPlatform = (token: string, refreshToken = "", shop = "", authCode = "") => {
        dispatch(authorizePlatform({
            accessToken: token,
            refreshToken,
            shop,
            authCode
        }));

    }

    const goToShopify = () => {
        window.open(SHOPIFY_APP_URL, "_self");
    }

    const getComponentFromPlatform = () => {
        switch (state.platform.type) {
            case PlatformTypes.KLAVIYO:
                return <input value={state.platform.accessToken} onChange={(e) => addPlatform(e.target.value)} className="border-2 my-4 rounded-lg p-2 w-[100%] " type="text" placeholder="Enter access token for Klaviyo" />

            case PlatformTypes.UNBOUNCE:
                return <div className="flex justify-around border-2 rounded-lg p-2 items-center">
                    <div className="flex items-center justify-center">
                        <img alt="logo" src="/assets/icons/unbounce.svg" />
                        Unbounce
                    </div>
                    <button className="bg-[#2F82FF] border-2 p-1 text-white h-10 w-20" >
                        <a href={UNBOUNCE_API} >Sign In</a>
                    </button>
                </div>
            case PlatformTypes.SHOPIFY:
                return <div className="flex justify-around border-2 rounded-lg p-2 items-center">
                    <div className="flex items-center justify-center mx-2">
                        <img alt="logo" src="/assets/icons/shopify.svg" className="h-16 w-16" />
                        Shopify
                    </div>
                    <div className="flex flex-end ">
                        <button onClick={goToShopify} className="bg-[#2F82FF] border-2 p-1 text-white h-10 w-20 mx-1 disabled:bg-gray-500 ">
                            Install app
                        </button>
                    </div>
                </div>
            default:
                return null
        }
    }


    return (
        <div className="bg-white rounded-md mt-2 p-2">
            <div className="my-2">
                Select action for <span className="font-bold">{state.platform.type}</span>
            </div>

            {getComponentFromPlatform()}

        </div>
    )
}

export default AccountAuthorize