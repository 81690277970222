import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import SubscriptionTable from "../../components/SubscriptionTable";
import Topbar from "../../components/Topbar";
import axiosInstance from "../../config/axios";
import { SHOPIFY_BACKEND_URL } from "../../constants/fetch";
import { PLAN_CONFIG, SubscriptionType } from "../../constants/plans";
import { PlatformTypes } from "../../constants/platform";
import { setProfile } from "../../context/actions/auth.action";
import { AuthContext } from "../../context/AuthContext";
import useQuery from "../../hooks/useQuery";
import { SubscriptionSource } from "../../types/auth.d";
import { IPlatform } from "../../types/platform";


const ManagePlans = () => {

    const { authState, authDispatch } = useContext(AuthContext);
    const [show, onShowChange] = useState(false);
    const [type, setType] = useState(SubscriptionType.PRO);
    const [platforms, setPlatforms] = useState<IPlatform[]>([])
    const query = useQuery();


    useEffect(() => {
        let timeout: any;
        if (query) {
            const chargeId = query.get("charge_id");
            if (chargeId) {
                timeout = setTimeout(() => {
                    window.location.href = "/manage-plan";
                }, 4000)
            }
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [query])

    useEffect(() => {
        getPlatforms();
    }, [])


    const getPlatforms = async () => {
        const { data } = await axiosInstance.get<IPlatform[]>("/platform/");
        const shopifyPlatforms = data.filter((platform) => platform.type === PlatformTypes.SHOPIFY);
        setPlatforms(shopifyPlatforms);
    }

    const subscriptionType = (authState.subscriptionType ? authState.subscriptionType : SubscriptionType.FREE) as SubscriptionType;
    const config = subscriptionType === SubscriptionType.PRO ? PLAN_CONFIG["PRO"] : subscriptionType === SubscriptionType.ENTERPRISE ? PLAN_CONFIG["ENTERPRISE"] : PLAN_CONFIG["FREE"];


    const onSubscribeClick = async (key: string, isCancel: boolean) => {
        if (isCancel && (key === SubscriptionType.ENTERPRISE || key === SubscriptionType.PRO)) {
            if (authState.subscriptionSource === SubscriptionSource.SHOPIFY) {
                const platform = platforms.find((platform) => platform.subscriptionId === authState.subscriptionId);
                if (platform) {
                    const { label, accessToken, subscriptionId: id } = platform;
                    await axios.post(SHOPIFY_BACKEND_URL + "/cancel-subscription", {
                        shop: label,
                        accessToken,
                        id
                    })
                    authDispatch(setProfile({
                        ...authState,
                        subscriptionType: "FREE",
                        subscriptionStatus: "UNPAID",
                        subscriptionEndDate: ""
                    }))
                }
                return;
            }
            await axiosInstance.post("/stripe/cancel-subscription");
            authDispatch(setProfile({
                ...authState,
                subscriptionCancelAtPeriodEnd: true
            }))
        }
        else {
            setType(key as SubscriptionType);
            onShowChange(true);
        }
    }

    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />

                    <div className="w-100 flex flex-col flex-grow m-2">
                        <SubscriptionTable />
                    </div>
                    <div className="flex flex-col ml-10 my-10">
                        <h2 className="text-xl font-bold my-4">Manage Plan</h2>

                        <div className="flex flex-col md:flex-row justify-around items-center">
                            {Object.entries(PLAN_CONFIG).map((entries) => {
                                const [key, value] = entries;
                                return <div key={key} className={`flex flex-col p-5 rounded-lg my-5 ${key === subscriptionType ? "bg-[#FF9F2F]" : "bg-[#DFE5EE] "} `}>
                                    <h3 className="font-bold text-md my-2">
                                        {key} Plan (${value.price} per month)
                                    </h3>

                                    <ul>
                                        {value.features.map((feat, i) => {
                                            return <li key={i} className="my-2">
                                                &#9989; {feat}
                                            </li>
                                        })}
                                    </ul>


                                    <button disabled={key === SubscriptionType.FREE} onClick={() => onSubscribeClick(key, key === subscriptionType)} className="uppercase p-2 bg-[#7E37D8] text-white my-2 rounded-md">
                                        {subscriptionType === key && key !== SubscriptionType.FREE ? "Cancel" : value.buttonText}
                                    </button>


                                </div>

                            })}
                        </div>
                    </div>
                </div>
            </div>

            <Modal paymentType={type} show={show} setShow={onShowChange} platforms={platforms} />
        </div>
    )
}

export default ManagePlans