import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_LOCAL_STORAGE } from "../constants/auth";
import useQuery from "../hooks/useQuery";



const getRedirectUrl = (query: URLSearchParams) => {
    const type = query.get("type");
    const accessToken = query.get("accessToken");
    const label = query.get("shop");
    if (type && accessToken && label) {
        return `?redirectTo=/create&type=${type}&accessToken=${accessToken}&shop=${label}`
    }
    return ""

}

const withAuth = (WrappedComponent: () => JSX.Element) => {
    return () => {
        const [loading, setLoading] = useState(true);
        const [rendered, setRendered] = useState(false);
        const navigate = useNavigate();
        const search = useQuery();

        const getAccessToken = () => {
            const accessToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE);
            return accessToken || "";
        }

        useEffect(() => {
            setRendered(true);
            return () => {
                setRendered(false);
            }

        }, [])

        useEffect(() => {
            if (!rendered) {
                return;
            }
            const accessToken = getAccessToken();
            const redirectUrl = getRedirectUrl(search);
            if (!accessToken) {
                navigate("/login" + redirectUrl)
            }
            else {
                setLoading(false);
            }

        }, [search, rendered]);


        if (loading) {
            return <div className="h-screen w-[100%] flex items-center justify-center uppercase text-xl">loading...</div>
        }
        return <WrappedComponent />;

    }

}

export default withAuth;