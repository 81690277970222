
import { v4 } from "uuid";
import { IEditBackendNotification } from "../../../types";
import Tooltip from "../../Tooltip";
import EditFilter from "../Filter/edit";


const options = [{
    label: "Include",
    value: "include"
},
{
    label: "Exclude",
    value: "exclude"
}]

const EditDataFilter = ({ state, dispatch }: IEditBackendNotification) => {
    const removeFilter = (id: string) => {
        const notification = { ...state };
        const dataFilters = state.dataFilters.filter((f) => f.id !== id);
        notification.dataFilters = dataFilters;
        dispatch(notification);
    }

    const addNewFilter = () => {

        const dataFilters = [...state.dataFilters];
        dataFilters.push({ id: v4(), include: false, exclude: false, text: "", fieldMapper: "" })
        const notification = { ...state };
        notification.dataFilters = dataFilters;
        dispatch(notification);
    }

    return (
        <div className='flex flex-col'>
            <h2 className='font-bold text-xl'>
                Notifications, Filter <Tooltip text="Filter only the required information from the data." />
            </h2>

            {state.dataFilters.map((filter) => (<EditFilter state={state} dispatch={dispatch} key={filter.id} id={filter.id} options={options} removeFilter={removeFilter} />))}

            <button onClick={() => addNewFilter()} className="my-5 p-2 rounded-md border-2 max-w-[200px] bg-[#2F82FF] text-white">
                Add Filter
            </button>

        </div>
    )
}

export default EditDataFilter