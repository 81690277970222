import { useNavigate } from "react-router-dom"
import Sidebar from "../../components/Sidebar"
import withAuth from "../../hoc/withAuth"
import { IPlatformTypes } from "../../types"
import Navbar from "./../../components/Navbar"
import Topbar from "./../../components/Topbar"
import { platformLists } from "./../../constants/platform"

const Home = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="ml-5">
                        <h2 className="font-bold text-xl my-4">
                            Recommended notifications for you
                        </h2>
                        {platformLists.map((platform: IPlatformTypes) => {
                            return <div key={platform.text} className={`flex justify-between items-center mb-2 bg-white border-2 rounded-lg p-1 mr-2 ${platform.enabled ? "" : "opacity-25"}`} >
                                <div className="flex items-center">
                                    <img src={platform.icon} alt="icon" className="h-16 w-16" />
                                    <p className="ml-2">{platform.text}</p>
                                </div>

                                <button disabled={!platform.enabled} onClick={() => navigate('/create')} className="bg-[#2F82FF] p-2 text-white mr-2 rounded-lg" >
                                    Try it now
                                </button>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAuth(Home)