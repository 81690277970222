
import { useEffect, useState } from "react";
import { CounterNotificationTimeOptions, COUNTER_NOTIFICATION_COUNT, initialMapperState, NotificationTypes } from "../../../constants/notification";
import { PlatformTypes } from "../../../constants/platform";
import { getScriptNotificationFromMapper, getScriptNotificationFromMapperUnbounce } from "../../../lib/notification";
import { IEditBackendNotification, IScriptNotification } from "../../../types";
import CounterNotification from "../../CounterNotification";
import EachNotification from "../../EachNotification";

const EditPublishNotification = ({ state }: IEditBackendNotification) => {


    const [actualNotification, setActualNotification] = useState<IScriptNotification>(initialMapperState)

    useEffect(() => {
        if (state.platform.type === PlatformTypes.SHOPIFY) {
            setActualNotification(state.platformData);
        }
        else if (state.mapper && state.platformData) {
            (async () => {
                if (state.platform.type === PlatformTypes.KLAVIYO) {
                    const scriptNotification = getScriptNotificationFromMapper(state, state.platformData);
                    setActualNotification(scriptNotification);
                }
                else {
                    const scriptNotification = await getScriptNotificationFromMapperUnbounce(state, state.platformData, actualNotification, true);
                    setActualNotification(scriptNotification);
                }
            })()
        }
    }, [state.mapper, state.platformData, state.platform.type])


    return (
        <div>
            <h2 className="mt-4 font-bold text-xl">
                Preview and Publish
            </h2>

            <p className="my-2">
                Check out the live preview of the notification from the live data
            </p>

            <div className="max-w-fit">
                {
                    state.type === NotificationTypes.EVENT_STREAM ?
                        <EachNotification data={actualNotification} /> :
                        <div className="border-2 p-2 rounded-lg">
                            <CounterNotification images={state.images && state.images?.length > 0 ? state.images : ["/assets/icons/snowflake.svg"]} total={COUNTER_NOTIFICATION_COUNT} beforeText={state.mapper.messageText.beforeText} afterText={state.mapper.messageText.afterText || (state.mapper.messageText.beforeText ? "" : "<add message here>")} time={state.counterTime || CounterNotificationTimeOptions.LAST_MONTH} />
                        </div>
                }

                {state.dataFilters.length > 0 &&
                    <span className="italic text-sm">
                        This is just a preview image of your <b>live data</b>. The actual data will be filtered!
                    </span>
                }
            </div>
        </div>
    )
}

export default EditPublishNotification