import { ReactNode } from 'react'
import AuthProvider from './AuthContext'
import NotificationProvider from './NotificationContext'


const AllContext = ({ children }: { children: ReactNode }) => {
    return (
        <AuthProvider>
            <NotificationProvider>
                {children}
            </NotificationProvider>
        </AuthProvider>
    )
}

export default AllContext