
import { IEditBackendNotification } from "../../../types";

const EditLabelNotification = ({ state, dispatch }: IEditBackendNotification) => {
    const setLabel = (label: string) => {
        const notification = { ...state };
        notification.label = label;
        dispatch(notification);
    }

    return (
        <input value={state.label} onChange={(e) => setLabel(e.target.value)} className="p-2 border-2 mr-6 mt-2 rounded w-[100%]" placeholder="Pick a name for your new social proof notification " />
    )
}

export default EditLabelNotification