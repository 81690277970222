import React, { ReactNode } from "react";
import AllContext from "./context/index.context";


const App: React.FC<{ children: ReactNode }> = ({ children }) => {

  return (
    <AllContext>
      {children}
    </AllContext>
  );
}

export default App;
