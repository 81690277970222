import { every, isEmpty, some } from "lodash";
import { NotificationSteps, NotificationTypes } from "../constants/notification";
import { PlatformTypes } from "../constants/platform";
import { INotification } from "../types";


export const checkNextDisabilityStatus = (step: NotificationSteps, state: INotification) => {
    if (step === NotificationSteps.CHOOSE_PLATFORM && state.platform.type !== PlatformTypes.NONE) {
        return false
    }
    else if (step === NotificationSteps.ACCOUNT_AUTHORIZE && state.platform.accessToken) {
        return false
    }
    else if (step === NotificationSteps.CHOOSE_SPECIFIC_METRIC && (state.platform.metric)) {
        return false
    }
    else if (step === NotificationSteps.NOTIFICATION_TYPE && state.style) {
        return !Boolean(state.type)
    }
    else if (step === NotificationSteps.NOTIFICATION_STYLE && state.style) {
        const isAnyValueEmpty = some(state.style, isEmpty);
        return isAnyValueEmpty
    }
    else if (step === NotificationSteps.MAPPER) {
        if (state.type === NotificationTypes.COUNTER) {
            const { beforeText, afterText } = state.mapper.messageText;
            return !((beforeText || afterText) && state.counterTime)
        }
        else {
            const { firstName, city, state: locationState, country, ip, messageText: { beforeText, fieldMapper, afterText }, date } = state.mapper;
            if (state.platform.type === PlatformTypes.SHOPIFY) {
                if ((beforeText && fieldMapper)) {
                    return false;
                }
            }
            if (firstName && (city || locationState || country || ip) && (beforeText || fieldMapper || afterText) && date) {
                return false
            }
        }
    }
    else if (step === NotificationSteps.DATA_FILTER) {
        const dataFilters = state.dataFilters;
        const isValid = every(dataFilters, (f) => {
            return (f.exclude || f.include) && f.fieldMapper && f.text;
        });
        return !isValid;
    }
    else if (step === NotificationSteps.URL_FILTER) {
        const urlFilter = state.urlFilter;
        if (urlFilter.showOnAll) {
            return false
        }
        else {
            if (!urlFilter.include.enable && !urlFilter.exclude.enable) {
                return true;
            }
            if (urlFilter.include.enable && urlFilter.include.options.length > 0 && urlFilter.include.options.find((f) => f.text.length > 0)) {
                return false;
            }
            if (urlFilter.exclude.enable && urlFilter.exclude.options.length > 0 && urlFilter.exclude.options.find((f) => f.text.length > 0)) {
                return false;
            }
        }
    }
    else if (step === NotificationSteps.BEHAVIOR_FILTER) {
        const { maxNumberOfEvents } = state;
        const { rotateAfterTime, showOnLoad, rules } = state.behaviors;
        if (maxNumberOfEvents > 0 && maxNumberOfEvents <= 50 && rotateAfterTime > 0 && (showOnLoad || rules.enable) && (rules.enable ? (parseInt(rules.showAfterPageScroll) > 0 || parseInt(rules.showAfterTime) > 0) : true)) {
            return false
        }
    }
    else if (step === NotificationSteps.LABEL_NOTIFICATION) {
        return !Boolean(state.label)
    }
    else if (step === NotificationSteps.PUBLISH_NOTIFICATION) {
        return false;
    }
    return true
}