import { isArray, isObject } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Navbar from "../../components/Navbar"
import AccountAuthorize from "../../components/Notification/AccountAuthorize"
import BehaviorFilter from "../../components/Notification/BehaviorFilter"
import ChoosePlatform from "../../components/Notification/ChoosePlatform"
import ChooseSpecificMetric from "../../components/Notification/ChooseSpecificMetric"
import CounterMapper from "../../components/Notification/CounterMapper"
import DataFilter from "../../components/Notification/DataFilter"
import LabelNotification from "../../components/Notification/LabelNotification"
import Mapper from "../../components/Notification/Mapper"
import NotificationStyle from "../../components/Notification/NotificationStyle"
import NotificationType from "../../components/Notification/NotificationType"
import PublishNotification from "../../components/Notification/Publish"
import UrlFilter from "../../components/Notification/UrlFilter"
import Sidebar from "../../components/Sidebar"
import Stepper from "../../components/Stepper"
import Topbar from "../../components/Topbar"
import axiosInstance, { validateAxios } from "../../config/axios"
import { NotificationSteps, NotificationTypes, ONGOING_NOTIFICATION_LOCAL_STORAGE } from "../../constants/notification"
import { PlatformTypes } from "../../constants/platform"
import { setNotificationFromStorage, setPlatform } from "../../context/actions/notification.action"
import { NotificationContext } from "../../context/NotificationContext"
import { initialNotificationState } from "../../context/reducers/notification.reducer"
import withAuth from "../../hoc/withAuth"
import { mapCurrentNotificationToBackendCreate } from "../../lib/notification"
import { checkNextDisabilityStatus } from "../../lib/validateState"
import { INotification } from "../../types"

const steps = [NotificationSteps.CHOOSE_PLATFORM, NotificationSteps.ACCOUNT_AUTHORIZE, NotificationSteps.CHOOSE_SPECIFIC_METRIC, NotificationSteps.NOTIFICATION_TYPE, NotificationSteps.NOTIFICATION_STYLE, NotificationSteps.MAPPER, NotificationSteps.DATA_FILTER, NotificationSteps.URL_FILTER, NotificationSteps.BEHAVIOR_FILTER, NotificationSteps.LABEL_NOTIFICATION, NotificationSteps.PUBLISH_NOTIFICATION];

const Notification = () => {
    const [step, setStep] = useState<NotificationSteps>(NotificationSteps.CHOOSE_PLATFORM);
    // const [step, setStep] = useState<NotificationSteps>(NotificationSteps.URL_FILTER);
    const { state, dispatch } = useContext(NotificationContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const stateFromLocalStorage = localStorage.getItem(ONGOING_NOTIFICATION_LOCAL_STORAGE);
        if (stateFromLocalStorage) {
            const oldState: INotification = JSON.parse(stateFromLocalStorage);
            if (isObject(oldState) && !isArray(oldState)) {
                dispatch(setNotificationFromStorage({ ...initialNotificationState, ...oldState, }));
            }
        }

    }, []);

    const authorizeCurrentApp = async () => {
        const payload = {
            accessToken: state.platform.accessToken,
            type: state.platform.type,
            shop: state.platform.label,
            refreshToken: state.platform.refreshToken,
            authCode: state.platform.authCode
        }

        const { data } = await axiosInstance.post("/platform/create", payload);
        setLoading(false);
        dispatch(setPlatform(data));
        if (state.platform.type !== PlatformTypes.SHOPIFY && !state.platform.metric) {
            setStep(NotificationSteps.CHOOSE_SPECIFIC_METRIC)
        }
        else {
            setStep(NotificationSteps.NOTIFICATION_TYPE)
        }
    }

    const addMetricToPlatform = async () => {
        const payload = {
            metric: state.platform.metric,
            label: state.platform.label
        }

        const { data } = await axiosInstance.put("/platform/" + state.platform._id, payload);
        setLoading(false);
        dispatch(setPlatform(data));
        setStep(NotificationSteps.NOTIFICATION_TYPE)
    }


    const publishNotification = async () => {
        const notificationData = mapCurrentNotificationToBackendCreate(state);
        await axiosInstance.post("/notification/create", notificationData, {
            headers: {
                hash: state.platform.hash
            }
        });
        dispatch(setNotificationFromStorage(initialNotificationState));
        setLoading(false);
        navigate("/install-script");
    }


    const goBack = () => {
        if (step === NotificationSteps.CHOOSE_SPECIFIC_METRIC) {
            setStep(NotificationSteps
                .CHOOSE_PLATFORM)
            return;
        }


        if (step === NotificationSteps.NOTIFICATION_TYPE && state.platform.type === PlatformTypes.SHOPIFY) {
            setStep(NotificationSteps.CHOOSE_PLATFORM);
            return;
        }
        const nextStep = steps.findIndex((eachStep) => eachStep === step);
        const indexOfNextStep = Math.max(nextStep - 1, 0);
        setStep(steps[indexOfNextStep]);
    }


    const goForward = async () => {
        if (state.platform.hash && step === NotificationSteps.CHOOSE_PLATFORM) {
            if (state.platform.type === PlatformTypes.SHOPIFY) {
                setStep(NotificationSteps.NOTIFICATION_TYPE)
            }
            else {
                setStep(NotificationSteps.CHOOSE_SPECIFIC_METRIC)
            }
            return;
        }

        if (step === NotificationSteps.ACCOUNT_AUTHORIZE) {
            setLoading(true);
            const error = await validateAxios(authorizeCurrentApp);
            if (error) {
                setError(error);
                setLoading(false);
            }
            return;
        }
        if (step === NotificationSteps.CHOOSE_SPECIFIC_METRIC && state.platform.type !== PlatformTypes.SHOPIFY) {
            setLoading(true);
            const error = await validateAxios(addMetricToPlatform);
            if (error) {
                setError(error);
                setLoading(false);
            }
            return;
        }

        if (step === NotificationSteps.PUBLISH_NOTIFICATION) {
            setLoading(true);
            const error = await validateAxios(publishNotification)
            if (error) {
                setError(error);
                setLoading(false);
            }
            return;
        }

        const nextStep = steps.findIndex((eachStep) => eachStep === step);
        const indexOfNextStep = Math.min(nextStep + 1, steps.length - 1);
        setStep(steps[indexOfNextStep]);
    }

    const componentWithStep = () => {
        switch (step) {
            case NotificationSteps.CHOOSE_PLATFORM:
                return <ChoosePlatform />
            case NotificationSteps.ACCOUNT_AUTHORIZE:
                return <AccountAuthorize />
            case NotificationSteps.CHOOSE_SPECIFIC_METRIC:
                return <ChooseSpecificMetric />
            case NotificationSteps.NOTIFICATION_TYPE:
                return <NotificationType />
            case NotificationSteps.NOTIFICATION_STYLE:
                return <NotificationStyle />
            case NotificationSteps.MAPPER:
                {
                    if (state.type === NotificationTypes.EVENT_STREAM)
                        return <Mapper />
                    return <CounterMapper />
                }
            case NotificationSteps.DATA_FILTER: return <DataFilter />
            case NotificationSteps.URL_FILTER: return <UrlFilter />
            case NotificationSteps.BEHAVIOR_FILTER: return <BehaviorFilter />
            case NotificationSteps.LABEL_NOTIFICATION: return <LabelNotification />
            case NotificationSteps.PUBLISH_NOTIFICATION: return <PublishNotification />
            default:
                return <ChoosePlatform />
        }
    }

    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="flex flex-col ml-10 my-10">
                        <div className="flex justify-between">
                            <h2 className="text-[24px]">
                                Create new notification
                            </h2>
                            <div className="mr-4">
                                <button onClick={() => {
                                    dispatch(setNotificationFromStorage(initialNotificationState));
                                    navigate("/notifications");
                                }} className="border-2 py-2 px-4 font-bold mx-2">
                                    Exit
                                </button>
                            </div>
                        </div>

                        {componentWithStep()}

                    </div>
                    <div className="flex items-center justify-center my-2">
                        {steps.map((_, i) => {
                            const index = steps.findIndex((x) => x === step);
                            return <Stepper key={i} step={i + 1} active={index > i} current={index === i} />
                        })}
                    </div>
                    <div className="flex items-center w-[100%] justify-around my-3">
                        <button disabled={step === NotificationSteps.CHOOSE_PLATFORM || loading} onClick={goBack} className="bg-[#2F82FF] text-white p-1 rounded-md w-[200px] border-2  disabled:bg-gray-500 disabled:text-white">
                            Back
                        </button>
                        <button disabled={checkNextDisabilityStatus(step, state) || loading} onClick={goForward} className="bg-[#2F82FF] text-white p-1 rounded-md w-[200px] border-2  disabled:bg-gray-500 disabled:text-white">
                            {step === NotificationSteps.PUBLISH_NOTIFICATION ? <>&#128640; Publish </> : "Next"
                            }
                        </button>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default withAuth(Notification)