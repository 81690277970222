import { useContext, useEffect, useState } from 'react';
import Select, { createFilter, SingleValue } from "react-select";
import axiosInstance, { validateAxios } from '../../../config/axios';
import { PlatformTypes } from '../../../constants/platform';
import { chooseMetric, setPlatform } from '../../../context/actions/notification.action';
import { NotificationContext } from '../../../context/NotificationContext';



const ChooseSpecificMetric = () => {
    const metricOptions: { label: string, value: string }[] = [];

    const { state, dispatch } = useContext(NotificationContext);
    const [metrics, setMetrics] = useState<any[]>([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);


    useEffect(() => {
        (async () => {
            const error = await validateAxios(getMetrics);
            if (error) {
                setError(error);
                setLoading(false);
            }
        })();
    }, [])

    useEffect(() => {
        if (state.platform.metric) {
            dispatch(chooseMetric(state.platform.metric));
        }
    }, [state.platform])

    const getMetrics = async () => {
        setLoading(true);
        const platform = state.platform.type.toLowerCase();
        const endPoint = "/platform/" + platform + "/get-metrics";
        const { data } = await axiosInstance.get<any>(endPoint, {
            headers: {
                hash: state.platform.hash
            }
        });
        setLoading(false);
        if (state.platform.type === PlatformTypes.KLAVIYO) {
            setMetrics(data);
        }
        else if (state.platform.type === PlatformTypes.UNBOUNCE) {
            const count: number = data?.metadata?.count || 0;
            setPage((count % 100) + 1);
            setHasMore(count !== 0 && count % 100 === 0);
            setMetrics(data.pages);
        }
    }

    const addMetric = (metric: SingleValue<{
        label: string;
        value: string;
    }>) => {
        dispatch(chooseMetric(metric?.value || ""));
        const platform = state.platform;
        platform.metric = metric?.value;
        platform.label = metric?.label;
        dispatch(setPlatform(platform))
    }

    metrics.forEach((metric: any) => {
        metricOptions.push({
            label: metric.name,
            value: metric.id
        })
    })

    const metricId = state.platform.metric || state.searchId;

    const defaultMetric = metricOptions.find((m) => m.value === metricId);

    const fetchAgain = async () => {
        if (state.platform.type === PlatformTypes.UNBOUNCE && hasMore) {
            try {
                setLoading(true);
                const platform = state.platform.type.toLowerCase();
                const endPoint = "/platform/" + platform + "/get-metrics?offset=" + page * 100;
                const { data } = await axiosInstance.get<any>(endPoint, {
                    headers: {
                        hash: state.platform.hash
                    }
                });
                const count: number = data?.metadata?.count || 0;
                setPage((count % 100) + 1);
                setHasMore(count !== 0 && count % 100 === 0);
                const pages = [...metrics, ...data.pages];
                setMetrics(pages);
                setLoading(false);
            }
            catch {
                setLoading(false);
            }
        }
    }


    return (
        <div>
            <div className='font-bold my-2'>Choose a {state.platform.type === PlatformTypes.KLAVIYO ? "metric" : "lead"}

            </div>
            <Select isLoading={loading} onMenuScrollToBottom={fetchAgain} className="my-2 max-w-[100%]"
                options={metricOptions} value={defaultMetric} filterOption={createFilter({ ignoreAccents: false })} onChange={(e) => addMetric(e)} />

        </div>
    )
}

export default ChooseSpecificMetric