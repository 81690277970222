import { useContext } from "react";
import { setDataFilters } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";
import Filter from "../Filter";
import { v4 } from "uuid"
import Tooltip from "../../Tooltip";


const options = [{
    label: "Include",
    value: "include"
},
{
    label: "Exclude",
    value: "exclude"
}]

const DataFilter = () => {


    const { state, dispatch } = useContext(NotificationContext);

    const removeFilter = (id: string) => {
        const dataFilters = state.dataFilters.filter((f) => f.id !== id);
        dispatch(setDataFilters(dataFilters));
    }

    const addNewFilter = () => {

        const dataFilters = [...state.dataFilters];
        dataFilters.push({ id: v4(), include: false, exclude: false, text: "", fieldMapper: "" })
        dispatch(setDataFilters(dataFilters));
    }

    return (
        <div className='flex flex-col'>
            <h2 className='font-bold text-xl'>
                Notifications, Filter <Tooltip text="Filter only the required information from the data." />
            </h2>

            {state.dataFilters.map((filter) => (<Filter key={filter.id} id={filter.id} options={options} removeFilter={removeFilter} />))}

            <button onClick={() => addNewFilter()} className="my-5 p-2 rounded-md border-2 max-w-[200px] bg-[#2F82FF] text-white">
                Add Filter
            </button>

        </div>
    )
}

export default DataFilter