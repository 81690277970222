import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance, { validateAxios } from "../../config/axios";
import { platformLists } from "../../constants/platform";
import { mapCurrentNotificationToBackendEdit } from "../../lib/notification";
import { IBackendNotification } from "../../types";
import { IPlatform } from "../../types/platform";
import ToggleSwitch from "../ToogleSwitch";


const Platform = ({ data }: { data: IPlatform }) => {
    const platformFromType = platformLists.find((p) => p.name === data.type);
    const [notificationLists, setNotificationLists] = useState<IBackendNotification[]>([]);


    const navigate = useNavigate();

    const fetchNotification = async () => {
        const response = await axiosInstance.get("/notification/fetch-all", {
            headers: {
                hash: data.hash,
            }
        });
        setNotificationLists(response.data);
    }

    useEffect(() => {
        (async () => {
            await validateAxios(fetchNotification);
        })();
    }, []);


    const deleteNotification = async (notification: IBackendNotification) => {
        const { _id } = notification;
        await axiosInstance.delete("/notification/" + _id);
        const notifications = notificationLists.filter((eachNotification) => eachNotification._id !== _id);
        setNotificationLists(notifications);
    }

    const toggleActive = async (id: string) => {
        const currentNotification = notificationLists.find((not) => not._id === id);
        if (!currentNotification) {
            return;
        }
        const backendNotificationData = mapCurrentNotificationToBackendEdit({
            ...currentNotification,
            active: !currentNotification?.active
        })
        await axiosInstance.put("/notification/" + id, backendNotificationData);
        const notifications = notificationLists.map((eachNotification) => {
            if (eachNotification._id !== id) {
                return eachNotification
            }
            return {
                ...eachNotification,
                active: !eachNotification.active
            }
        });
        setNotificationLists(notifications);
    }

    const editNotification = (id: string) => {
        navigate("/edit-notification/" + id);
    }

    return (
        <div className="flex flex-col">
            <div className="flex items-center">
                <img src={platformFromType?.icon || ""} className="h-16 w-16" alt="logo" />
                <span className="mx-2">
                    {data.label || data.hash}
                </span>
            </div>
            <div className="flex flex-col mx-10 my-4">
                {notificationLists.map((eachNotificaton) => {
                    return <div className="border-2 bg-white p-2 rounded-lg my-2 flex items-center justify-between" key={eachNotificaton._id}>
                        <div className="flex justify-center items-center">
                            <button onClick={() => editNotification(eachNotificaton._id)}>
                                <img src="/assets/icons/edit.svg" className="h-6 w-6 mr-2" alt="edit" />
                            </button>
                            <div className="font-bold text-xl">
                                {eachNotificaton.label}
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="hover:cursor-pointer">
                                <ToggleSwitch active={eachNotificaton.active} toggleActive={toggleActive} id={eachNotificaton._id} />
                            </div>
                            <button onClick={() => deleteNotification(eachNotificaton)} className="border-2 p-2 rounded-lg">
                                <img src="/assets/icons/delete.svg" className="w-8 h-8" alt="delete" />
                            </button>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Platform