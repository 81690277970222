
const Topbar = () => {
    return (
        <div className='bg-topbar flex items-center justify-center text-white  mx-1 p-2 text-[2vw] md:text-[1vw]'>
            <div>
                Welcome to UpGiant &#127881;. Our company is
            </div>
            <div className="text-[#07D0EC] mx-1">100% carbon neutral.</div>
        </div>
    )
}

export default Topbar