import axios from "axios";
import { ACCESS_TOKEN_LOCAL_STORAGE } from '../constants/auth';
import { REFRESH_TOKEN_LOCAL_STORAGE } from './../constants/auth';
import { BACKEND_URL } from './../constants/fetch';



export const validateAxios = async (callback: () => Promise<void | string>) => {
    try {
        await callback();
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                const errorResponse = error.response.data as any;
                return JSON.stringify(errorResponse?.message) || "Something went wrong"
            }
            else if (error.request) {
                return "Request is invalid"
            }
            return "Something went wrong"

        }
        return 'Something went wrong';
    }
}

const getLocalAccessToken = () => {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE);
    return accessToken
}

const getLocalRefreshToken = () => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE);
    return refreshToken;
}


const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
    },
});


axiosInstance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        config.headers = { ...config.headers };
        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const fetchRequestToken = async () => {
    const { data } = await axiosInstance.post("/auth/refresh", {}, {
        headers: {
            jwt: getLocalRefreshToken() || ""
        }
    });
    return {
        refreshToken: data.refreshToken,
        accessToken: data.accessToken
    }
}

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const res = await fetchRequestToken();
                    const { accessToken, refreshToken } = res;
                    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE, accessToken);
                    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE, refreshToken);
                    axiosInstance.defaults.headers.common["Authorization"] = accessToken;

                    return axiosInstance(originalConfig);
                } catch (_error: any) {
                    localStorage.clear();
                    window.location.href = "/login";
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

export default axiosInstance;