import { useContext, useState } from 'react';
import { ChromePicker } from 'react-color';
import Select from "react-select";
import { fontSize, fontStyle, fontTypes, languageOptions, NotificationTypes, transitionEffect } from '../../../constants/notification';
import { addStyle, setNotificationFromStorage } from '../../../context/actions/notification.action';
import { NotificationContext } from '../../../context/NotificationContext';
import { IScriptNotification } from '../../../types';
import EachNotification from '../../EachNotification';
import Tooltip from '../../Tooltip';


const NotificationStyle = () => {


    const { state, dispatch } = useContext(NotificationContext);
    const [colorChosen, setColorChosen] = useState(false);

    const fontStyleMap = fontStyle.map((font) => ({ label: font, value: font }));
    const selectedFontStyle = fontStyleMap.find((x) => x.value === state.style.font.style);
    const fontSizeMap = fontSize.map((font) => ({ label: font, value: font }));
    const selectedFontSize = fontSizeMap.find((x) => x.value === state.style.font.size.toString());
    const fontTypesMap = fontTypes.map((font) => ({ label: font, value: font }));
    const selectedFontType = fontTypesMap.find((x) => x.value === state.style.font.type);
    const languageOptionsMap = languageOptions.map((lang) => ({ label: lang, value: lang }))
    const selectedLanguage = languageOptionsMap.find((x) => x.value === state.style.language);
    const transitionEffectMap = transitionEffect.map((eff) => ({ label: eff, value: eff }))
    const selectedTransitionEffect = transitionEffectMap.find((x) => x.value === state.style.transition);

    const dummyDate = new Date();
    dummyDate.setHours(dummyDate.getHours() - 1);

    const dummyData: IScriptNotification = {
        firstName: "Chris",
        state: "Florida",
        country: "US",
        messageText: {
            beforeText: "signed up for",
            fieldMapper: "Upgiant",
            afterText: ""
        },
        date: dummyDate.toString(),
        image: "/assets/icons/snowflake.svg",
        destination: ""
    }


    const addStreamType = (event: string) => {
        const notification = { ...state };
        notification.type = event as NotificationTypes.EVENT_STREAM;
        dispatch(setNotificationFromStorage(notification))
    }

    const addColor = (color: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.titleColor = color;
        dispatch(addStyle(notificationStyle))
    }

    const setFontType = (type: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.font.type = type;
        dispatch(addStyle(notificationStyle));
    }

    const setFontStyle = (style: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.font.style = style;
        dispatch(addStyle(notificationStyle));
    }

    const setFontSize = (size: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.font.size = parseInt(size);
        dispatch(addStyle(notificationStyle));
    }

    const setPosition = (position: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.position = position;
        dispatch(addStyle(notificationStyle));
    }

    const selectLanguage = (language: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.language = language;
        dispatch(addStyle(notificationStyle));
    }

    const setTransition = (transition: string) => {
        const notificationStyle = { ...state.style };
        notificationStyle.transition = transition;
        dispatch(addStyle(notificationStyle));
    }

    return (
        <div>
            <h2 className='font-bold my-4'>
                Notifications Style
            </h2>


            <div className='my-2 border-2 p-2'>
                <h4 className='font-bold'>
                    Color
                    <Tooltip text="color of the main field" />
                </h4>
                <div className='flex items-center '>
                    <span onClick={() => setColorChosen(x => !x)} className='p-2 border-2'>{state.style.titleColor}</span>
                    <div style={{
                        backgroundColor: state.style.titleColor
                    }} className={`h-8 w-8 ml-2`}></div>
                </div>
                {colorChosen &&
                    <ChromePicker className='absolute z-10' color={state.style.titleColor} onChangeComplete={(color) => { addColor(color.hex); setColorChosen(false) }} />}
            </div>
            <div className='mt-4'>
                <h4 className='font-bold'>
                    Font
                </h4>
                <div className='flex items-center'>
                    <Select value={selectedFontType} options={fontTypesMap} onChange={(e) => setFontType(e?.value || "")} />
                    <Select value={selectedFontStyle} className='mx-4' options={fontStyleMap} onChange={(e) => setFontStyle(e?.value || "")} />
                    <Select value={selectedFontSize} className='mr-4' options={fontSizeMap} onChange={(e) => setFontSize(e?.value || "")} />
                </div>
            </div>
            <div className='mt-4'>
                <h4 className='font-bold'>
                    Notification Position
                    <Tooltip text="Where in your website you want the notification to be displayed?" />
                </h4>
                <div className='flex items-center'>
                    <div onClick={() => setPosition("Bottom Left")} className={` p-2 mr-2 rounded-xl hover:cursor-pointer ${state.style.position !== "Bottom Left" ? "border-[#a02929] border-2 text-black bg-white" : "text-white bg-black"}`}>
                        Bottom Left
                    </div>
                    <div onClick={() => setPosition("Bottom Right")} className={` p-2 mr-2 rounded-xl hover:cursor-pointer ${state.style.position !== "Bottom Right" ? "border-[#a02929] border-2 text-black bg-white" : "text-white bg-black"}`}>
                        Bottom Right
                    </div>
                    <div onClick={() => setPosition("Top Left")} className={` p-2 mr-2 rounded-xl hover:cursor-pointer ${state.style.position !== "Top Left" ? "border-[#a02929] border-2 text-black bg-white" : "text-white bg-black"}`}>
                        Top Left
                    </div>
                    <div onClick={() => setPosition("Top Right")} className={` p-2 mr-2 rounded-xl hover:cursor-pointer ${state.style.position !== "Top Right" ? "border-[#a02929] border-2 text-black bg-white" : "text-white bg-black"}`}>
                        Top Right
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <h4 className='font-bold'>
                    Notification Language
                </h4>

                <Select className='max-w-fit' options={languageOptionsMap} value={selectedLanguage} onChange={(e) => selectLanguage(e?.value || "")} />
            </div>
            <div className='mt-4'>
                <h4 className='font-bold'>
                    Transition Effect
                    <Tooltip text="type of notification animation effect" />
                </h4>

                <Select options={transitionEffectMap} value={selectedTransitionEffect} onChange={e => setTransition(e?.value || "")} />
            </div>
        </div>
    )
}

export default NotificationStyle