import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import EditNotificationWithSteps from "../../../components/Notification/EditNotificationWithStep";
import Sidebar from "../../../components/Sidebar";
import Topbar from "../../../components/Topbar";
import axiosInstance from "../../../config/axios";
import { initialNotificationState } from "../../../context/reducers/notification.reducer";
import { IBackendNotification } from "../../../types";



const EditNotification = () => {
    const [notification, setNotification] = useState<IBackendNotification>({ ...initialNotificationState, _id: "", active: true });
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            const { data } = await axiosInstance.get("/notification/" + id);
            data.dataFilters = data.dataFilters?.map((f: any) => { return { ...f, id: f._id } }) || [];
            setNotification(data);
        })();
    }, [])



    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="flex flex-col ml-10 my-10">
                        <div className="flex justify-between">
                            <h2 className="text-[24px]">
                                Edit notification
                            </h2>
                        </div>
                        <EditNotificationWithSteps data={notification} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditNotification