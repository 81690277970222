import { useRef, useState } from "react";

interface ITooltipProps {
    children?: React.ReactElement,
    text: string;
}

const Tooltip = ({ text, children }: ITooltipProps) => {
    const [show, setShow] = useState(false);

    const handleMouseEnter = () => {
        setShow(true);
    }
    const handleMouseLeave = () => {
        setShow(false);
    }
    return (
        <span className="items-center inline-flex mx-1 justify-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src="/assets/icons/question.svg" alt="question-mark" className="h-4 w-4" />
            <span className={` text-sm p-1 absolute z-10 bg-black text-white translate-y-[-30px] ${show ? "" : "invisible"} `}>
                {text}
            </span>

        </span>
    );
}


export default Tooltip;