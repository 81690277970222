import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';


const getDate = (time: string) => {
    const nowDate = new Date(time);
    return nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate()
}



const SubscriptionTable = () => {
    const { authState } = useContext(AuthContext);
    const isBillingActive = authState.subscriptionStatus === "active";
    return (
        <>
            <h3 className='font-bold m-4'>Your Current Plan</h3>
            <table className="table-fixed mx-4 border-2">
                <thead>
                    <tr className='bg-[#7E37D8] text-white font-bold px-6 border-2 p-2 rounded-lg'>
                        <th className='p-2'>Type</th>
                        <th>Status</th>
                        <th> {isBillingActive ? "Next Billing Date" : "Expired Date"}</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    <tr className='bg-[#F8FAFC] font-bold px-6 border-2 p-2 rounded-sm'>
                        <td>{authState.subscriptionType || "FREE"}</td>
                        <td className='uppercase'>{authState.subscriptionStatus || "INACTIVE OR UPAID"}</td>
                        <td>
                            {isBillingActive && authState.subscriptionEndDate ?
                                getDate(authState.subscriptionEndDate) : "Coulnd't determine"
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default SubscriptionTable