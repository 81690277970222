import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { SUPPORT_EMAIL } from "../../constants/platform";

const HelpPage = () => {

    return (
        <div className="bg-home">
            <Topbar />
            <div className="flex flex-col md:flex-row ">
                <Sidebar />
                <div className="flex-grow border-2">
                    <Navbar />
                    <div className="flex flex-col ml-10 my-10">
                        <div className="flex justify-between">
                            <h2 className="text-[24px] font-bold">Contact Support</h2>
                        </div>

                        <div className="bg-white p-2 border-2 mt-4 mb-2  flex items-center justify-between w-[100%] md:w-[50%]">
                            <img src="/assets/icons/email.svg" alt="email" className="mx-2 w-16 h-16" />

                            Send us an email

                            <button className="bg-[#2F82FF] py-1 px-4 text-white mx-4">
                                <a href={"mailto:" + SUPPORT_EMAIL}>
                                    Email us
                                </a>
                            </button>
                        </div>

                        <div className="bg-white p-2 border-2 mt-2 mb-4 flex items-center justify-between w-[100%] md:w-[50%] opacity-50">
                            <img src="/assets/icons/chat.svg" alt="email" className="mx-2 w-16 h-16" />

                            Chat with Us

                            <button className="bg-[#2F82FF] py-1 px-4 text-white mx-4">
                                Not available
                            </button>
                        </div>

                        <div className="mt-5">
                            Email us at <a className="text-[#2F82FF]" href={"mailto:" + SUPPORT_EMAIL}>
                                {SUPPORT_EMAIL}
                            </a>

                            <div className="font-bold my-4">
                                Mon-Fri
                                <div className="text-sm italic font-normal">
                                    7AM EST - 7PM EST
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpPage