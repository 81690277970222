
interface IStepper {
    step: number,
    active: boolean,
    current: boolean
}

const Stepper = ({ step, active, current }: IStepper) => {
    return (
        <div className={`mx-2 my-2 rounded-full w-[24px] h-[24px] p-2 flex items-center justify-center text-white ${current ? "bg-[#ff0000]" : active ? "bg-[#3351F5]" : "bg-[#808080]"} `}>
            {step}
        </div>

    )
}

export default Stepper