import { useContext } from "react"
import { NotificationTypes } from "../../../constants/notification";
import { setBehavior, setNotificationFromStorage } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext"
import Tooltip from "../../Tooltip";

const BehaviorFilter = () => {

    const { state, dispatch } = useContext(NotificationContext);

    const setPageLoad = () => {
        const behaviorFilter = state.behaviors;
        behaviorFilter.showOnLoad = !behaviorFilter.showOnLoad;
        behaviorFilter.rules.enable = false;
        dispatch(setBehavior(behaviorFilter));
    }

    const enableBasedOnRules = () => {
        const behaviorFilter = state.behaviors;
        behaviorFilter.rules.enable = !behaviorFilter.rules.enable;
        behaviorFilter.showOnLoad = false;
        dispatch(setBehavior(behaviorFilter));
    }

    const setShowAfterTime = (val: string) => {

        const behaviorFilter = state.behaviors;
        behaviorFilter.rules.showAfterTime = val;
        dispatch(setBehavior(behaviorFilter));
    }

    const setScrollAfterTime = (val: string) => {

        const behaviorFilter = state.behaviors;
        behaviorFilter.rules.showAfterPageScroll = val;
        dispatch(setBehavior(behaviorFilter));
    }

    const setMaxNumberOfEvents = (val: string) => {
        const currentState = state;
        currentState.maxNumberOfEvents = parseInt(val);
        dispatch(setNotificationFromStorage(currentState));
    }

    const setRotationSpeed = (val: string) => {
        const behaviorFilter = state.behaviors;
        behaviorFilter.rotateAfterTime = parseInt(val);
        dispatch(setBehavior(behaviorFilter));
    }

    return (
        <div>
            <h2 className='font-bold text-xl'>
                Notifications, Behavior
            </h2>

            <div className="my-4 flex items-center">
                <input checked={state.behaviors.showOnLoad} onChange={setPageLoad} className="mr-2" type="checkbox" />
                Show on page load
                <Tooltip text="Load the notification as soon as the page finishes loading" />
            </div>

            <div className="my-4 flex items-center">
                <input checked={state.behaviors.rules.enable} onChange={enableBasedOnRules} className="mr-2" type="checkbox" />
                <div>
                    Show based on rules:
                </div>
            </div>
            <div className="ml-4">
                show <input value={state.behaviors.rules.showAfterTime} onChange={(e) => setShowAfterTime(e.target.value)} type={"number"} min={2} className="border-2 min-w-[50px]" /> seconds after page load
                <Tooltip text="Show after given seconds of page load" />
            </div>
            <div className="ml-4">
                show after scrolling <input value={state.behaviors.rules.showAfterPageScroll} onChange={(e) => setScrollAfterTime(e.target.value)} type={"number"} min={5} className="border-2 min-w-[50px]" /> % of the page
                <Tooltip text="Show after given percentage of page is scrolled" />
            </div>

            {
                state.type === NotificationTypes.EVENT_STREAM &&
                <>
                    <div className="my-4">
                        <h2 className="my-2 font-bold text-lg uppercase">
                            Event Maximum
                        </h2>

                        show most recent <input value={state.maxNumberOfEvents} onChange={(e) => setMaxNumberOfEvents(e.target.value)} type={"number"} className="border-2 min-w-[50px]" /> events
                        <Tooltip text="How many notification do you want to show?" />
                    </div>

                    <div className="my-4">
                        <h2 className="my-2 font-bold text-lg uppercase">
                            Speed between transitions
                        </h2>

                        <div className="my-2">
                            How fast should notifications transition to the next popup?
                        </div>

                        Rotate every <input value={state.behaviors.rotateAfterTime} onChange={(e) => setRotationSpeed(e.target.value)} type={"number"} className="border-2 min-w-[30px] " /> seconds
                        <Tooltip text="Retention time for each notification" />

                    </div>
                </>
            }

        </div>
    )
}

export default BehaviorFilter