import { v4 } from "uuid";
import { CounterNotificationTimeOptions, NotificationTypes, ONGOING_NOTIFICATION_LOCAL_STORAGE } from "../../constants/notification";
import { PlatformTypes } from "../../constants/platform";
import { INotification } from "../../types";
import { NotificationAction } from "../actions/notification.action.d";

export const initialNotificationState: INotification = {
    customDisable: false,
    platform: {
        _id: "",
        user: "",
        type: PlatformTypes.NONE,
        hash: "",
        accessToken: "",
        refreshToken: "",
        authCode: "",
        subscriptionId: "",
    },
    searchId: "",
    type: NotificationTypes.EVENT_STREAM,
    style: {
        titleColor: "#FF8268",
        font: {
            size: 16,
            type: "DM Sans",
            style: "Normal"
        },
        position: "Bottom Left",
        language: "English",
        transition: "Disappear"
    },
    mapper: {
        firstName: "",
        city: "",
        state: "",
        country: "",
        ip: "",
        messageText: {
            beforeText: "",
            fieldMapper: "",
            afterText: ""
        },
        date: "",
        image: "",
        destination: ""
    },
    dataFilters: [],
    urlFilter: {
        include: {
            enable: false,
            options: [
                {
                    option: "exact",
                    text: "",
                    id: v4()
                }
            ]
        },
        exclude: {
            enable: false,
            options: [
                {
                    option: "exact",
                    text: "",
                    id: v4()
                }
            ]
        },
        showOnAll: false
    },
    behaviors: {
        showOnLoad: true,
        rules: {
            enable: false,
            showAfterTime: "",
            showAfterPageScroll: ""
        },
        rotateAfterTime: 5
    },
    maxNumberOfEvents: 5,
    label: "",
    platformData: null,
    counterTime: CounterNotificationTimeOptions.LAST_MONTH
};

export const allNotificationsReducer = (state: INotification, action: NotificationAction): INotification => {
    switch (action.type) {
        case "SET_NOTIFICATION": {
            const newState = { ...action.payload };

            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));

            return newState;
        }

        case "TOGGLE_NOTIFICATION_NEXT": {
            const newState = { ...state, customDisable: action.payload };
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        case "CHOOSE_PLATFORM": {
            const newState = {
                ...state,
                platform: {
                    ...action.payload
                }
            };
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));

            return newState;
        }
        case "AUTHORIZE_PLATFORM": {
            const newState = {
                ...state,
                platform: {
                    ...state.platform,
                    ...action.payload
                }
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }
        case "CHOOSE_METRIC": {
            const newState = {
                ...state,
                searchId: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;

        }
        case "ADD_STYLE": {
            const newState = {
                ...state,
                style: { ...action.payload }
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        case "SET_IMAGES": {
            const newState = {
                ...state,
                images: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        case "ADD_FIELD_MAPPER": {
            const newState = {
                ...state,
                mapper: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        case "SET_DATA_FILTERS": {
            const newState = {
                ...state,
                dataFilters: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        case "SET_URL_FILTER": {
            const newState = {
                ...state,
                urlFilter: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }
        case "SET_BEHAVIOR_FILTER": {
            const newState = {
                ...state,
                behaviors: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }
        case "SET_NOTIFICATION_LABEL": {
            const newState = {
                ...state,
                label: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }
        case "SET_PLATFORM_DATA": {
            const newState = {
                ...state,
                platformData: action.payload
            }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }


        case "SET_COUNTER_NOTIFICATION_TIME": {
            const newState: INotification = { ...state, counterTime: action.payload }
            localStorage.setItem(ONGOING_NOTIFICATION_LOCAL_STORAGE, JSON.stringify(newState));
            return newState;
        }

        default:
            return state;

    }
}