
import { createContext, Dispatch, ReactNode, useEffect, useReducer } from 'react';
import axiosInstance from '../config/axios';
import { IUser } from '../types/auth';
import { setProfile } from './actions/auth.action';
import { AuthAction } from './actions/auth.action.d';
import { allAuthReducer, initialAuthState } from './reducers/auth.reducer';



export const AuthContext = createContext<{ authState: IUser, authDispatch: Dispatch<AuthAction> }>({
    authState: initialAuthState,
    authDispatch: () => { }
});

const AuthProvider = ({ children }: { children: ReactNode }) => {

    const [authState, authDispatch] = useReducer(allAuthReducer, initialAuthState);

    useEffect(() => {
        const url = window.location.pathname;
        if ((url !== "/login" && authState?._id) || url === "/manage-plan") {
            (async () => {
                const { data } = await axiosInstance.get("/auth/profile");
                authDispatch(setProfile(data));
            })()
        }
    }, [authState._id])

    return (
        <AuthContext.Provider value={{ authState, authDispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

