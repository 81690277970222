

import { isArray, isString } from "lodash";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import axiosInstance from "../../../config/axios";
import { CounterNotificationTimeOptions, COUNTER_NOTIFICATION_COUNT } from "../../../constants/notification";
import { AWS_S3_BUCKET_URL, PlatformTypes } from "../../../constants/platform";
import { addMapper, setCounterNotificationTime, setImages } from "../../../context/actions/notification.action";
import { NotificationContext } from "../../../context/NotificationContext";
import { mapShopifyOrderFromData } from "../../../lib/platform";
import { IMapper } from "../../../types";
import CounterNotification from "../../CounterNotification";
import Tooltip from "../../Tooltip";

const CounterMapper = () => {


    const { state, dispatch } = useContext(NotificationContext);
    const [data, setData] = useState<any | null>(null);

    useEffect(() => {
        if (state.platform.type === PlatformTypes.KLAVIYO || state.platform.type === PlatformTypes.UNBOUNCE) {
            fetchData();
        }
    }, [state.platform.metric])


    const fetchData = async () => {
        const platform = state.platform.type.toLowerCase();
        const backendUrl = "/platform/" + platform + "/get-data";
        if (state.platform.type === PlatformTypes.KLAVIYO) {
            const { data } = await axiosInstance.post(backendUrl, {
                metricId: state.platform.metric,
                count: 1
            }, {
                headers: {
                    hash: state.platform.hash
                }
            });
            setData(data.data[0]);
        }
        else if (state.platform.type === PlatformTypes.UNBOUNCE) {
            const { data } = await axiosInstance.post(backendUrl, {
                metricId: state.platform.metric,
                offset: 0,
                limit: 3
            }, {
                headers: {
                    hash: state.platform.hash
                }
            });
            setData(data.leads?.[0]);
        }
        else if (state.platform.type === PlatformTypes.SHOPIFY) {
            const { data } = await axiosInstance.post(backendUrl, {
                label: state.platform.label,
                limit: 1,
            }, {
                headers: {
                    hash: state.platform.hash,
                }
            });
            const scriptNotification = mapShopifyOrderFromData(data, state.mapper);
            setData(scriptNotification);
        }
    }


    const timeTopOptionsValues = state.platform.type !== PlatformTypes.SHOPIFY ? Object.values(CounterNotificationTimeOptions) : Object.values(CounterNotificationTimeOptions).slice(0, 4);

    const timerOptions = (state.platform.type !== PlatformTypes.SHOPIFY ? Object.keys(CounterNotificationTimeOptions) : Object.keys(CounterNotificationTimeOptions).slice(0, 4)).map((key, index) => {
        return {
            value: key,
            label: timeTopOptionsValues[index]
        }
    })

    const handleInputImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (files) {
            const allFiles = Object.entries(files).map((f) => f[1]);
            if (allFiles.length < 11) {
                const formData = new FormData();
                for (const f of allFiles) {
                    formData.append("images", f);
                }
                try {
                    const response = await axiosInstance.post("/upload", formData, {
                        headers: {
                            hash: state.platform.hash
                        }
                    });
                    const images = response.data.map((d: any) => AWS_S3_BUCKET_URL + d.key);
                    dispatch(setImages(images));
                }
                catch {
                    console.log("Error uploading images to the backend");
                }
            }
        }
    }



    const handleTimeSelection = (time: string) => {
        const timeOption = timerOptions.find((opt) => opt.value === time);
        if (timeOption) {
            dispatch(setCounterNotificationTime(timeOption.label))
        }
    }

    const setMessageTextBeforeText = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const mapper: IMapper = { ...state.mapper };
        mapper.messageText.beforeText = fields;
        dispatch(addMapper(mapper));
    }

    const setMessageTextAfterText = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const mapper = { ...state.mapper };
        mapper.messageText.afterText = fields;
        dispatch(addMapper(mapper));
    }


    const setEventDate = (value: string | string[]) => {
        const fields = isString(value) ? value : isArray(value) ? value.join("->") : ""
        const mapper = { ...state.mapper };
        mapper.date = fields;
        dispatch(addMapper(mapper));
    }

    const defaultTimerOption = timerOptions.find((opt) => opt.label === state.counterTime);

    const countOptions = [{ label: "Count", value: "Count" }];


    return (
        <div className='flex flex-col'>
            <h2 className='font-bold text-xl'>
                Counter Notification Data Mapper <Tooltip text="Map your data and check the preview of the notification" />
            </h2>

            <div className='max-w-fit my-4'>
                <div className="border-2 p-2 rounded-lg">
                    <CounterNotification images={state.images && state.images?.length > 0 ? state.images : ["/assets/icons/snowflake.svg"]} total={COUNTER_NOTIFICATION_COUNT} beforeText={state.mapper.messageText.beforeText} afterText={state.mapper.messageText.afterText || (state.mapper.messageText.beforeText ? "" : "<add message here>")} time={state.counterTime || CounterNotificationTimeOptions.LAST_MONTH} />
                </div>
            </div>


            <div className='flex items-center my-2'>
                <label className='w-40'>
                    <Tooltip text="Write the message text. Map any data using the dropdown in the middle" />
                    Message Text
                </label>
                <div className='ml-2 flex-grow'>
                    <div className='flex justify-between items-center'>
                        <input value={state.mapper.messageText.beforeText} onChange={(e) => setMessageTextBeforeText(e.target.value)} className='border-2 p-2 rounded-lg' placeholder='add message here...' />
                        <div className='flex-grow mx-2' >
                            <Select options={countOptions} value={countOptions[0]} />
                        </div>
                        <input value={state.mapper.messageText.afterText} onChange={(e) => setMessageTextAfterText(e.target.value)} className='border-2 p-2 rounded-lg' placeholder='add message here...' />
                    </div>
                </div>
            </div>

            <div className="flex items-center my-2">
                <div className="mr-2 w-40">
                    Time:
                </div>
                <div className="flex-grow mr-2">
                    <Select value={defaultTimerOption} onChange={(e) => handleTimeSelection(e?.value || "")} options={timerOptions} />
                </div>
            </div>

            <div className='flex my-2'>
                <label className='w-40'>
                    <Tooltip text="Upload images (at max 10) that picks random image for each notification" />
                    Image:
                </label>
                <div className='mx-2'>
                    <input onChange={handleInputImages} className='' type="file" multiple accept="image/*" />
                </div>
            </div>
        </div>
    )
}

export default CounterMapper