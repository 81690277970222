import { isArray, isBoolean, isEmpty, isNumber, isObject, isString } from "lodash";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";

interface IDataMapperProps {
    data?: { [x: string]: any },
    onChange?: (val: string | string[]) => void,
    selectedValue?: string,
    includeCustom?: boolean;
}

const Option = (props: any) => {
    return <components.Option {...props}>
        <div className="flex flex-col justify-center border-b-2">
            <div>
                {props.label}
            </div>
            <h4 className="mt-1 text-[12px] italic font-bold">
                {props.value.join("->")}
            </h4>
        </div>
    </components.Option>
}

const getHeirarchy = (data: { [x: string]: any } = {}, heirarchy: { [x: string]: string[] }, prevNodes: string[] = []) => {
    for (const [key, value] of Object.entries(data)) {
        if (isBoolean(value) || isString(value) || isNumber(value) || isArray(value)) {
            heirarchy[key] = [...prevNodes, key]
        }
        else if (isObject(value)) {
            getHeirarchy(value, heirarchy, [...prevNodes, key]);
        }
    }
    return heirarchy;
}

const DataMapper = ({ data = {}, onChange, selectedValue, includeCustom = true }: IDataMapperProps) => {

    const [options, setOptions] = useState<{ label: string, value: string[] }[]>([]);
    const [defaultValue, setDefaultValue] = useState<{ label: string, value: string[] }>();
    const [isCustomSelected, setIsCustomSelected] = useState(false);
    const [customValue, setCustomValue] = useState("");


    useEffect(() => {
        if (!data || isEmpty(data)) {
            return;
        }
        let heirarchy: { [x: string]: string[] } = {};
        const updatedHeirarchy = getHeirarchy(data, heirarchy);
        const optionsForData = Object.entries(updatedHeirarchy).map(([label, value]) => ({ label, value }))
        if (includeCustom) {
            optionsForData.push({ label: "Custom", value: ["UPGIANT_CUSTOM->"] });
        }
        setOptions(optionsForData);
    }, [data])

    useEffect(() => {
        if (selectedValue?.startsWith("UPGIANT_CUSTOM->")) {
            setIsCustomSelected(true);
            const value = selectedValue.replace("UPGIANT_CUSTOM->", "");
            setCustomValue(value);
            const defaultValue = options.find((option) => option.value?.[0].startsWith("UPGIANT_CUSTOM->"));
            setDefaultValue(defaultValue);
        }
        else {
            setIsCustomSelected(false);
            setCustomValue("");
            const defaultValue = options.find((option) => option.value.join("->") === selectedValue);
            setDefaultValue(defaultValue);
        }
    }, [selectedValue, options])

    return (
        <div className="flex flex-col">
            <Select value={defaultValue || null} onChange={(e) => {
                if (e?.value?.[0] === "UPGIANT_CUSTOM->") {
                    setIsCustomSelected(true);
                }
                else {
                    setIsCustomSelected(false);
                }
                if (onChange) {
                    onChange(e?.value || []);
                }
            }} options={options} components={{ Option }} />

            {isCustomSelected && <input onChange={(e) => {
                if (onChange) {
                    setCustomValue(e.target.value);
                    const newValue = "UPGIANT_CUSTOM->" + e.target.value.replace("UPGIANT_CUSTOM->", "");
                    onChange(newValue);
                }
            }}
                value={customValue} className="p-2 border-2 my-2" />}
        </div>
    )
}

export default DataMapper 