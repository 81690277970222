import _, { after } from "lodash";
import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import { nFormatter } from "../../lib/string";
import { ICounterNotification } from "../../types";

const CounterNotification = ({ total, beforeText, afterText, images, time }: ICounterNotification) => {
    const { state } = useContext(NotificationContext);
    const image = _.sample(images);
    return (
        <div className="flex items-center justify-center">
            <img src={image} alt="image-icon" className="h-16 w-16" />
            <div className="flex flex-col justify-center">
                <div className="flex my-4">
                    <div className="mx-1">
                        {beforeText}
                    </div>
                    <span style={{
                        color: state.style.titleColor,
                        fontSize: state.style.font.size,
                    }}>{nFormatter(total)}</span>
                    <div className="mx-1">
                        {afterText}
                    </div>

                </div>

                <div className="flex justify-between w-[100%]">
                    <div className="flex flex-col mx-2">
                        <div className="flex items-center justify-center">
                            <img src="/assets/icons/time.svg" alt="image-icon" className="h-4 w-4" />
                            <div className="mx-1">
                                {time}
                            </div>
                        </div>

                    </div>
                    <div className="mr-4 text-sm ml-auto ">
                        <span className="bg-[#A020F0] rounded-full h-4 w-4 mr-1">
                            <span className="text-white p-1">
                                &#10004;
                            </span>
                        </span>
                        <span className="font-bold text-[#A020F0]">Upgiant</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CounterNotification